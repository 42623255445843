import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatTabsModule } from '@angular/material/tabs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalInterceptorInterceptor } from './shared/admin-interceptor/global-interceptor.interceptor';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GlobalErrorHandlerService } from './shared/error-handler/global-error-handler.service';
import { UserUmbrellaComponent } from './modules/user-umbrella/user-umbrella/user-umbrella.component';
import { ConnectCrafterComponent } from './modules/user-umbrella/connect-crafter/connect-crafter.component';
import { RetryInterceptor } from './shared/retry-interceptor/retry.interceptor';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/json/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    UserUmbrellaComponent,
    ConnectCrafterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,

    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    MatProgressBarModule,
    MatTabsModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: GlobalInterceptorInterceptor, multi: true },
  { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
  { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
