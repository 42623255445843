import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-img-crafter',
  templateUrl: './img-crafter.component.html',
  styleUrls: ['./img-crafter.component.scss']
})
export class ImgCrafterComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ImgCrafterComponent>) { }

  ngOnInit(): void {
    console.log(this.data)
  }
}
