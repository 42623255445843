import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError,debounceTime,distinctUntilChanged,switchMap,BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  
  ptldUrl: string = environment.tldURL+'/ptld';
  baseUrl: string = environment.baseUrl;
  service1: string = 'commons-iam-service';
  private storedEmail: string | null = null;
  private storedPassword: string | null = null;
  constructor(private http: HttpClient) { }

  getLoggedInStatus() {
    return this.loggedIn.asObservable();
  }

  setLoggedInStatus(status: boolean) {
    this.loggedIn.next(status);
  }

  //seach organization
  searchOrganizations(query: string): Observable<any> {
    const apiUrl = `${this.baseUrl}/commons-report-service/api/v3/datasets/name/ASSESSMENT_ORG-SEARCH-Tenant/execute`;
    if (query.length >= 3) {
      const headers = new HttpHeaders({
        'Authorization': environment.Appkey, 
      });

      return this.http
        .get(`${apiUrl}?page=0&params=TENANT_NAME=${encodeURIComponent(query)}%25&size=10`, { headers })
        .pipe(
          debounceTime(100),
          distinctUntilChanged(),
          switchMap((data: any) => {
            return of(data);
          }),
          catchError((error) => {
            console.error('Error searching organizations:', error);
            return of([]);
          })
        );
    } else {
      return of([]);
    }
  }

//login after otp verification
  loginAfterOtpVerification(email: string, password: string, tenant:string): Observable<any> {
    const apiUrl = `${this.baseUrl}/commons-iam-service/api/v1/obo/cross/login`;
    const body = {
      password: password,
      tenantLogin: tenant,

      userLogin: email
    };

    return this.http.post(apiUrl, body);
  }

  loginAfterOtpVerificationOrg( password: string, tenant:string, email: string): Observable<any> {
    const apiUrl = `${this.baseUrl}/commons-iam-service/api/v1/obo/cross/login`;
    const body = {
      password: password,
      tenantLogin: tenant,
      userLogin: email
    };

    return this.http.post(apiUrl, body);
  }



  registerOrganization(userData: any): Observable<any> {
    const apiUrl =`${this.baseUrl}/commons-iam-service/api/v1/signup/register`;    
    return this.http.post(apiUrl, userData);
  }

//store data for login
  storeCredentials(email: string, password: string) {
    this.storedEmail = email;
    this.storedPassword = password;
  }

  getStoredEmail(): string | null {
    return this.storedEmail;
  }

  getStoredPassword(): string | null {
    return this.storedPassword;
  }

  resendOtp(email: string, org: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('stateless-authentication', 'true')
      .set('tenantName', org)
      .set('X-USER', email);
  
    const apiUrl = `${this.baseUrl}/commons-iam-service/api/v1/obo/resend-otp`;
    const options = { headers: headers, responseType:  'text' as 'json' };
  
    return this.http.get(apiUrl, options);
  }
  
  resetPassword(email: string, org: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('stateless-authentication', 'true')
      .set('tenantName', org)
      .set('X-USER', email);
  
    const apiurl = `${this.baseUrl}/commons-iam-service/api/v1/obo/reset-password`;
  
    const options = { headers: headers, responseType: 'text' as 'json' };
    return this.http.get(apiurl, options);
  }

  submitPasswordReset(key: string, otp: string, newPassword: string, enteredEmail: string, org: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('stateless-authentication', 'true')
      .set('tenantName', org) 
      .set('X-USER', enteredEmail)
      .set('X-NEWPASS', newPassword);

    const apiUrl = `${this.baseUrl}/commons-iam-service/api/v1/obo/reset-password?key=${encodeURIComponent(key)}&otp=${otp}`;

    return this.http.put(apiUrl, {}, { headers:headers, responseType: 'text' as 'json' });
  }
  

  activateUser(email: string, tenantName: string,defaultTenant:string): Observable<any> {
    const headers = new HttpHeaders()
    .set('stateless-authentication', 'true');

    const apiUrl = `${this.baseUrl}/commons-iam-service/api/v1/obo/activate-inactive-user?tenantName=${encodeURIComponent(tenantName || defaultTenant)}&userName=${encodeURIComponent(email)}`;
  
    return this.http.get(apiUrl, {headers});
  }

  resendOtpOrganization(email: string, key: string): Observable<any> {
    const apiUrl = `${this.baseUrl}/commons-iam-service/api/v1/signup/otp`;
    const queryParams = { email, key };
    const headers = new HttpHeaders({ 'Accept': '*/*' });

    return this.http.put(apiUrl, null, { params: queryParams, headers });
  }

  signUpIndividual(data: any, tenantName: string, password:string): Observable<any> {
    const apiUrl = `${this.baseUrl}/commons-iam-service/api/v1/obo/register?tenantName=${encodeURIComponent(tenantName)}`;
    const headers = new HttpHeaders().set('X-PASS', password);
  
    return this.http.post(apiUrl, data, {
      headers: headers,
      observe: 'response',
      responseType: 'json'
    })
  }

  
  verifyOtpIndividual(userId: number, modKey: string, otp: string, tenantName: string): Observable<any> {
    const apiUrl = `${this.baseUrl}/commons-iam-service/api/v1/obo/activate-user?userId=${userId}`;
    const headers = new HttpHeaders()
    .set('stateless-authentication', 'true')
      .set('modKey', modKey)
      .set('otp', otp)
      .set('tenantName', tenantName); 

    const options = {
      headers: headers,
      responseType: 'text' as 'json',
    };

    return this.http.post(apiUrl, null, options).pipe(
      catchError(this.handleError)
    );
  }

  verifyOtpOrganization(payload: any): Observable<any> {
    const url = `${this.baseUrl}/commons-iam-service/api/v1/signup/tenant/vms?addToVWSDatasetGroup=false`;
    const headers = new HttpHeaders()
      .set('X-PASS', payload.password);
    return this.http.post(url, payload, { headers });
  }

  getUserDetails(): Observable<any> {
    const url = `${this.baseUrl}/auth-service/api/v1/users/me`;
    return this.http.get(url);
  }

  checkUserActiveStatus(email: string, orgId: string) {
    const headers = new HttpHeaders()
    .set('stateless-authentication', 'true');

    const url = `${this.baseUrl}/commons-iam-service/api/v1/obo/register/check`;
    const queryParams = `loginName=${encodeURIComponent(email)}&tenantName=${encodeURIComponent(orgId)}`;

    return this.http.get(`${url}?${queryParams}`,{headers,responseType: 'text'});
  }

  fetchUserDataByEmail(email: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': environment.Appkey, 
    });

    const apiUrl = `${this.baseUrl}/commons-report-service/api/v3/datasets/name/ASSESSMENT_ORG-GetUserTenantName/execute?page=0&params=EMAIL=${encodeURIComponent(email)}&size=0`;
    return this.http.get(apiUrl,{headers:headers});
  }

  selfRegistration(data: any): Observable<any> {
    const url = `${environment.tldURL}/ctld/api/user/selfregistration/v2`;
  
    const params = {
      tenantLogin: data['context'],
      emailOrMobile: data.email,
      name: data.email
    };
  
    return this.http.post(url, null, { params: params });
  }
  
  takerverifyOtp(data: { modKey: string, otp: string, tenantLogin: string, userLogin: string }): Observable<any> {
    const headers = new HttpHeaders().set('stateless-authentication', 'true');
    const url = `${this.baseUrl}/commons-iam-service/api/v1/signup/quick-login`;
  
    return this.http.post(url, data, { headers: headers });
  }
  

  authenticate(data: any): Observable<any> {
    let url = `${this.baseUrl}/auth-service/api/auth/v1/login`;
    let authPayload = data;

    return this.http.post(
      url,
      authPayload,
      {
        responseType: "json"
      }).pipe(
        catchError(this.handleError)
      );
  }

  authenticateViaSession(data: any): Observable<any> {
    let urlPost = this.baseUrl + '/' + this.service1 + '/api/v1/obo/login';

    const headers = new HttpHeaders()
      .set('X-APPID', environment.XAPPID)
      .set('X-APPKEY', environment.XAPPKEY)
      .set('Content-Type', 'application/json')
      .set('stateless-authentication', 'true');

    return this.http.post(urlPost, data,
      {
        headers: headers,
        observe: "response"
      }).pipe(
        catchError(this.handleError)
      );
  }

  generateToken(): Observable<any> {
    let url = `${this.baseUrl}/auth-service/api/session/v1`;

    return this.http.post(
      url,
      null,
      {
        responseType: "json"
      }).pipe(
        catchError(this.handleError)
      );
  }


  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => errorMessage);
  }


  sendOtpForTaker(email: string, orgName: string): Observable<any> {
    const apiUrl = `${environment.tldURL}/ctld/api/session/v1/otp/`;
  
    const headers = new HttpHeaders({
      'X-USER': `${email}@@${orgName}`,
    });
  
    return this.http.get(apiUrl, { headers, responseType: 'text' });
  }

  verifyOtpForTaker(email: string, orgName: string, otp: string): Observable<any> {
    const apiUrl = `${environment.tldURL}/ctld/api/session/v2/otp`;
  
    const headers = new HttpHeaders({
      'otp': otp,
      'tenantName': orgName,
    });
  
    const params = new HttpParams().set('userLogin', email);
    // const options = { headers: headers, observe:'response', params: params };
  
    return this.http.post(apiUrl, {}, {headers: headers, observe:'response', params: params});
  }
}
