import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-child-question-preview',
  templateUrl: './child-question-preview.component.html',
  styleUrls: ['./child-question-preview.component.scss']
})
export class ChildQuestionPreviewComponent implements OnInit {
  @Input() question: any;
  @Input() selectedLangCode:any;
  @Input() imgConfiguration: any;
  constructor() { }

  ngOnInit(): void {
    console.log(this.question);
    
  }

  displayQuestionType(questionForm: any, cssClass?: boolean, isForm?: boolean) {
    const questionType = isForm ? questionForm : questionForm.get('questionType').value;
    switch (questionType) {
      case 'QUESTION_TYPE.SUBJECTIVE_SHORT':
        return cssClass ? 'ques-short' : 'Short';
        break;
      case 'QUESTION_TYPE.SUBJECTIVE_LONG':
        return cssClass ? 'ques-paragraph' : 'Paragraph';
        break;
      case 'QUESTION_TYPE.DATE':
        return cssClass ? 'ques-date' : 'Date';
        break;
      case 'QUESTION_TYPE.NUMBER':
        return cssClass ? 'ques-number' : 'Number';
        break;
      case 'QUESTION_TYPE.FILE_UPLOAD':
        return cssClass ? 'ques-file-upload' : 'File Upload';
        break;
      case 'QUESTION_TYPE.SCALE_RATING':
        return cssClass ? 'ques-linear-scale' : 'Linear Scale';
        break;
      case 'QUESTION_TYPE.OBJECTIVE':
        return cssClass ? 'radio-ext-icon' : 'Multiple Choice';
        break;
      case 'QUESTION_TYPE.POLL_OPEN':
        return cssClass ? 'radio-ext-icon' : 'POLL';
        break;
      case 'QUESTION_TYPE.DDOBJECTIVE':
        return cssClass ? 'ques-ddobjective' : 'Dropdown Objective';
        break;
      case 'QUESTION_TYPE.MULTISELECT':
        return cssClass ? 'ques-checkbox' : 'Multiselect';
        break;
      case 'QUESTION_TYPE.DDMULTISELECT':
        return cssClass ? 'ques-dd-objective' : 'Dropdown Multiselect';
        break;
      case 'QUESTION_TYPE.OBJECTIVE_SUBJECTIVE':
        return cssClass ? 'ques-list' : 'Objective + Remarks';
        break;
      case 'QUESTION_TYPE.MULTISELECT_SUBJECTIVE':
        return cssClass ? 'ques-list-check' : 'Multiselect + Remarks';
        break;
      case 'QUESTION_TYPE.SINGLE_SELECT_GRID':
        return cssClass ? 'ques-list-check' : 'Single Select Grid';
        break;
      case 'QUESTION_TYPE.MULTISELECT_GRID':
        return cssClass ? 'ques-list-check' : 'Multi Select Grid';
        break;

      default:
        return '';
        break;
    }

  }

  getText(textArray: any[]) {    
    const text = textArray?.find(text => text?.language?.code === this.selectedLangCode);    
    return text?.text;

  }
}
