<div class="container px-md-5 position-sticky parent">
<div *ngIf="pendingCount > 0" class="headerr d-flex">
    <div class="content d-flex pt-2">
        <div class="assessment-text-medium-16 heading assessment-text-grey-ten">
            <img class="me-2" [src]="imgConfiguration['SURVEY_APP.APPROVE_BLUE_ICON']" alt="">
            {{'REQUEST.APPROVAL'|translate}} ({{ pendingCount }} {{'PENDING'|translate}})
        </div>
            
        <a class="assessment-text-semibold-14 btn btn-md view-member-btn" [routerLink]="['/workplace/user-list']">
            {{'VIEW.MEMBERS'|translate}}
        </a>          
    </div>
</div>
</div>