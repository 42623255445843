<section class="col-12 h-100 d-flex flex-column justify-content-between">
    <div class="overflow-auto">
        <span class="assessment-text-grey-ten assessment-text-bold-13">{{'SELECT.ONE.DOMAIN'|translate}}</span>
        <div class="col-12 mt-5 w-100 d-flex flex-column flex-wrap flex-sm-row">
            <div class="col-md-3 p-3 col-12" *ngFor="let domain of domainNames">
                <div class="custom-view cursor-pointer box-layout d-flex justify-content-center align-items-center"
                    (click)="selectDomainForNewForm(domain)">
                    <span>{{ domain }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end position-sticky">
        <button mat-button class="btn-shade-4 px-3 my-3" (click)="closeAll()">{{'COMMON.CANCEL'|translate}}</button>
    </div>
</section>