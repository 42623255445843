import { ErrorHandler, Injectable } from '@angular/core';
import * as objectHash from 'object-hash';
import { environment } from 'src/environments/environment';
import { getLocalStorageItem, getLocalStorageItemEncrypt, setLocalStorageItem } from '../utility/utility';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MainService } from 'src/app/services/main.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MsgRollerComponent } from '../utility-components/msg-roller/msg-roller.component';
import { AppConfigService } from 'src/app/services/app-config.service';
import { SessionExpiredComponent } from '../components/session-expired/session-expired.component';

@Injectable({
  providedIn: 'root'
})

export class GlobalErrorHandlerService implements ErrorHandler {
  dialogOpen: boolean;
  errorCounters: any = {};
  constructor(private deviceService: DeviceDetectorService,
    private mainService: MainService,
    private snackbar: MatSnackBar,
    private router: Router,
    private matDialog: MatDialog,
    public appService: AppConfigService,
    ) {
    console.log('Handler')
  }

  handleError(error: any): void {
    console.log(error)
    console.error(error);
    if (error.status != 401 && error.status != 0 && error?.error?.status != 0) {
      if (error.status == 500 && (error?.errorMessage == "Forwarding error" || error?.error?.errorMessage == "Forwarding error")
        && !this.dialogOpen) {
        this.dialogOpen = true;
        this.openDialogForwardingErrorDialog();

        this.snackbar.open('We are upgrading our system for a better experience. Please allow us a couple of minutes and we will be back', 'OK', { duration: 5000 });
        let hashForError:any = objectHash(error);
        this.logError(hashForError)
        if (this.errorCounters[hashForError] <= 5) {
          this.sendErrorMailToSupportTeam(error);
        }
        this.sendErrorMailToSupportTeam(error);
      } else if (!(error.status == 404 && (error.url.includes('iconpic')))
        && !(error.status == 500 && error.url.includes('personidentifier/attachment'))
        && !(error?.message?.includes('ChunkLoadError')) && !window.location.href.includes('localhost:')) {
        let hashForError:any = objectHash(error);
        this.logError(hashForError)
        if (this.errorCounters[hashForError] <= 5) {
          this.sendErrorMailToSupportTeam(error);
        }
      }
    }

    if ((error.status == 401  || error.toString().includes('"status":401')) && (error['error']?.errorCode=='BadJWTException' || error['error']?.errorCode=='ERR_PLATFORM_GEN_UNAUTHENTICATED' || error['error']?.errorCode=='ERR_PLATFORM_SESSION_CONTEXT_INVALID_SESSION' || error['error']?.errorMessage?.toString().includes('is not valid'))) {
      if(environment.defaultOnwer=='echo'){
        const dialogRef = this.matDialog.open(SessionExpiredComponent, {
          panelClass: '',
          data:''
        });
        dialogRef.afterClosed().subscribe((result:any) => {
          console.log(result)
          window.location.reload();
        });      
      }else{
        this.logOut();
        this.snackbar.open('Session expired, please login', 'OK', { duration: 3000 });
        setLocalStorageItem('access', 'public')
        this.router.navigate(['/login'])
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  }

  logError(errorKey: any) {
    if (sessionStorage.getItem(environment.appContext + '.errorCounter')) {
      let tempObj:any=sessionStorage.getItem(environment.appContext + '.errorCounter')
      this.errorCounters = JSON.parse(tempObj)
    }
    if (this.errorCounters.hasOwnProperty(errorKey)) {
      this.errorCounters[errorKey] += 1;
    } else {
      this.errorCounters[errorKey] = 1;
    }
    sessionStorage.setItem(environment.appContext + '.errorCounter', JSON.stringify(this.errorCounters))
  }

  logOut() {
    [localStorage, sessionStorage].forEach(storage => {
      Object.keys(storage).forEach(key => {
        if (key.startsWith(environment.appContext + '.') || key.startsWith(environment.respondentContext + '.')) {
          storage.removeItem(key);
        }
      });
    });
  }

  sendErrorMailToSupportTeam(error: any) {
    let appConfig:any=this.appService.getConfigData();

    let session_id: any = getLocalStorageItemEncrypt('X-Sessionid');

    let errorInfo: any = {
      sessionId: session_id,
      browser: this.deviceService.browser + ' ' + this.deviceService.browser_version,
      deviceType: this.deviceService.deviceType,
      os: this.deviceService.os,
      method: error.method ? error.method : "",
      error: JSON.stringify(error, ["message", "arguments", "type", "name", "status", "url", "error"]),
      errorDetails: error.error ? error.error : "",
      errorPage: window.location.href,
      payload: error.payload ? error.payload : "",
      sessionStorageState: this.allSessionStorageKeys(),
      localStorageState: this.allLocalStorageKeys()
    }
    console.log(errorInfo);

    errorInfo = JSON.stringify(errorInfo);
    const sendNotificationViaEmail = {
      fromTenantId: getLocalStorageItem('userId'),
      fromUser: 'hi@commons.team',
      groupEmail: false,
      id: 0,
      isActive: true,
      notificationCode: 'notif.user.issue.create.email',
      params: {

        MSG_TEXT: errorInfo,
        MSG_SUBJECT: appConfig['SURVEY_APP_CONFIG.ERROR_SUBJECT_LINE']

      },
      sentDate: Date.now(),
      status: true,
      toUsers: [
        appConfig['SURVEY_APP_CONFIG.TECH_EMAIL_NOTIFIERS']
      ],
    };
    let commonSessionID = {
      'X-SESSIONID': session_id
    }
    this.mainService.postServiceBuilder('/notification-service/api/v1/notification', null, sendNotificationViaEmail, commonSessionID)
      .subscribe({
        next: (notificationResponseDataEmail: any) => {
          console.log('EMAIL NOTIFICATION SENT SUCCESSFULLY', notificationResponseDataEmail);

        }, error: notificationError => {
          console.error(notificationError);
          console.error('unable to send notifications')
        }
      });
  }


  allSessionStorageKeys() {
    let archive = [],
      keys = Object.keys(sessionStorage),
      i = 0, key;
    for (; key = keys[i]; i++) {
      archive.push(key + '=' + sessionStorage.getItem(key));
    }
    return archive;
  }

  allLocalStorageKeys() {
    let archive = [],
      keys = Object.keys(localStorage),
      i = 0, key;
    for (; key = keys[i]; i++) {
      archive.push(key + '=' + localStorage.getItem(key));
    }
    return archive;
  }

  openDialogForwardingErrorDialog() {
    const dialogRef = this.matDialog.open(MsgRollerComponent, {
      width: '80vw',
      maxWidth: '100vw',
      height: '80vh',
      data: {
        msg: '',
        type: 'system-update'
      }, disableClose: true
    });
  }
}

