import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { getLocalStorageItem } from '../shared/utility/utility';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let userAccess = getLocalStorageItem('access')
      if (userAccess && userAccess=='private') {
        return false
      }
      return true;
  }
}
