<div class="dropdown cursor-pointer  ">
  <button class="d-flex flex-row justify-content-between align-items-center  dropbtn w-100 cursor-pointer  ">
    <div class="d-flex flex-column align-items-start ">
      <span class="assessment-text-grey-eight assessment-text-medium-10">{{hoverableDropdownName}}</span>
      <span class="assessment-text-grey-ten assessment-text-medium-13 word-wrapper">{{selectedFilter}}</span>
    </div>
    <img class="ms-3" [src]="imgConfiguration['SURVEY_APP.DOWN_ARROW_ICON']">
  </button>
  <div class="dropdown-content">
    <div *ngFor="let item of dataList">
      <a class="text-nowrap assessment-text-medium-13 "
        (click)="onSelectItem(item)"
        [ngClass]="selectedFilter==item.value?'assessment-text-primary-one':'assessment-text-grey-ten'">{{item.value}}</a>
    </div>
  </div>
</div>