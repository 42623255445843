<div class="d-flex flex-column align-items-center main-container">

  <div *ngIf="currentStep === 1" class="step1">

    <div class="gif-container">
    <img [src]="imgConfiguration['SURVEY_APP.GIF_TWO']" alt="">
    </div>
    <p class="assessment-text-medium-26 assessment-text-grey-ten text-center">
      {{ 'TAKER.SURVEY.REQUIREMENT' | translate }}<br>{{ 'YOU.TO.SIGNIN' | translate }}
    </p>

    <div class="d-flex flex-column align-items-baseline sign-in-cotainer">


      <div class="d-flex justify-content-center mt-3 ps-2" *ngIf="isSocialLoginInProcess">
        <mat-spinner class="mx-auto" [diameter]="20"></mat-spinner>
      </div>

      <div class="d-flex justify-content-center flex-column align-items-center w-100">
        <div class="w-100 d-flex justify-content-center m-0 p-0 " id="google-button" *ngIf="!isSocialLoginInProcess"></div>

        <button class="btn-shade-2 assessment-text-medium-18 mt-4" (click)="setCurrentStep(2)">
          {{ 'TAKER.SIGNIN.USING.OTP' | translate }}
        </button>
      </div>
    </div>

  </div>



  <ng-container *ngIf="currentStep === 2">
    <div class="ps-5 w-100 position-relative margin-auto">
      <div>
        <p class="assessment-text-grey-ten assessment-text-bold-36">{{'SIGN.IN'|translate}}</p>
        <p class="assessment-text-grey-nine assessment-text-regular-20 mt-4">{{'EMAIL.LABEL'|translate}}</p>
        <div class="email-container bottom-border-input pb-4 d-flex justify-content-between">
          <input id="emailInput" type="email" [(ngModel)]="email" name="email" class="border-0 w-91 custom-height"
            [ngClass]="{'assessment-text-regular-18 assessment-text-grey-nine opacity-6 ': !email, 'assessment-text-grey-ten assessment-text-medium-20': email}"
            placeholder="you@company.com" required />
          <!-- <img src="assets/images/svgs/right.svg" alt=""
            class="cursor-pointer border-0" (click)="submitEmail()" /> -->
          <img *ngIf="isValidEmail()" [src]="imgConfiguration['SURVEY_APP.RIGHT_ARROW']" alt=""
            class="cursor-pointer border-0" (click)="submitEmail()" />
        </div>

      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="currentStep === 3">
    <div class="w-91 d-flex flex-column margin-auto position-relative">
      <p class="assessment-text-regular-16 assessment-text-grey-eight cursor-pointer" (click)="goBack()">
        <img [src]="imgConfiguration['SURVEY_APP.SINGLE_LEFT_ICON']" alt="" class="me-1">
        {{'GO.BACK'|translate}}
      </p>
      <p class="assessment-text-grey-ten assessment-text-bold-36 sign-in">{{'SIGN.IN'|translate}}</p>
  
      <div class="user-input-box">
        <input id="emailInput" type="email" [(ngModel)]="email" name="email"
          class="bottom-border-input assessment-text-grey-ten assessment-text-medium-20 w-100 cursor-disabled" disabled>
        <br>
        <input id="otpInput" type="text" [(ngModel)]="otp" name="otp"
          class="bottom-border-input assessment-text-grey-ten assessment-text-bold-36 margin-10 w-100" placeholder="{{'LOGIN.ENTER.OTP'|translate}}"
          required>
        <p class="assessment-text-grey-nine assessment-text-regular-16 opacity-8 mt-2">{{'TAKER.CHECK.INBOX'|translate}}</p>
      </div>
      <button class="btn-shade-2 assessment-text-medium-18 margin-10" [disabled]="otp.length !== 6 || isLoading"
        (click)="verifyOtp()">
        <div *ngIf="isLoading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></div>
        {{'TAKER.SIGNIN.USING.OTP'|translate}}
      </button>
    </div>
  </ng-container>
</div>