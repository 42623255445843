import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, concatMap, delay, of, retryWhen, throwError } from 'rxjs';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen(errors => 
        errors.pipe(
          concatMap((error, count) => {
            if (count <= 5 && error instanceof HttpErrorResponse && error.status === 502) {
              // Retry up to 5 times, with a delay
              return of(error).pipe(delay(300));
            }
            return throwError(error);
          })
        )
      ),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
