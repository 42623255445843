import { Component, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { MainService } from 'src/app/services/main.service';
import { getLocalStorageItem, setLocalStorageItem } from '../../utility/utility';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from 'src/app/modules/login/login/login.component';
import { AuthService } from 'src/app/services/auth.service';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isDrawerOpen = false;
  @Input() isScrolled: any;
  @Input() loggedIn = true;
  isAnimationPage: boolean = false;
  userProfile: any;
  profileImg: string;
  newLangObjList: any;
  appLanguage: any = getLocalStorageItem('language');
  isAdmin: boolean;
  isNavbarOpen = true;
  isNavbarCollapsed = true;
  activePage: string;
  companyName: string = '';
  selectedLang: string = getLocalStorageItem('language');
  imgConfiguration: any;

  constructor(private router: Router,
    public translate: TranslateService,
    public appService: AppConfigService,
    public mainService: MainService,
    public dialog: MatDialog,
    private authService: AuthService,
    private renderer: Renderer2,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.imgConfiguration=this.mainService.getImage()

    this.authService.getLoggedInStatus().subscribe((status) => {
      this.loggedIn = status;
    });

    this.mainService.profileProjector.subscribe(response => {
      this.userProfile = response
      if (this.userProfile && this.userProfile['person']?.iconpic) {
        this.profileImg = this.userProfile['person'].iconpic
      } else {
        this.profileImg = this.addDefaultImg()
      }
    })

    let userAccess = getLocalStorageItem('access');
    if (userAccess == 'public') {
      this.loggedIn = false;
    } else if (userAccess == 'private') {
      this.loggedIn = true;
      this.fetchCompanyName();
      //this.mainService.fetchUserProfile();
    }
    this.fetchAppLangList();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const firstChild = this.route.firstChild;

        if (firstChild) {
          let currentRoute = firstChild.snapshot.routeConfig?.path;
          currentRoute = window.location.pathname.endsWith('/welcome') ? 'welcome' : currentRoute

          if (currentRoute) {
            this.activePage = currentRoute;
          }
        }
      }
    });
  }

  fetchCompanyName(): void {
    this.mainService.getCompanyName().subscribe({
      next: (data: any) => {
        const companyData = data[0];
        if (companyData) {
          this.companyName = companyData.name;

          setLocalStorageItem('isAdmin', companyData.isTenatAdmin === 1 ? 'true' : 'false');
          const isAdminString = getLocalStorageItem('isAdmin');
          this.isAdmin = isAdminString === 'true';

        }
        return;
      },
      error: (error: any) => {
        console.error('Error fetching company name:', error);
        throw error;
      }
    });
  }

  toggleNavbar(): void {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }


  navigateToUserList() {
    this.router.navigate(['/workplace/user-list']);
  }


  openLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: 'login-dialog',
      data: {
        isDrawerMode: true,
      },
    });

    dialogRef.componentInstance.isDialogMode = true;

    dialogRef.afterClosed().subscribe((result) => {
    });
  }



  addDefaultImg() {
    return 'assets/images/svgs/avatar.svg'
  }

  fetchAppLangList() {
    this.appService.fetchAppLangList().subscribe({
      next: (response: any) => {
        const data: any = response;
        this.appLanguage = data[this.appLanguage];
        this.newLangObjList = Object.entries(data).map(([key, value]) => ({
          name: key,
          value: value
        }));
      },
      error: (error: any) => {
        console.error('Error fetching app language list:', error);
        throw error;
      }
    });
  }

  navigatePath(pageIndex: string) {

    if (pageIndex === 'home/sector') {
      this.router.navigateByUrl('/home/sector');
    }
    if (pageIndex === 'workplace') {
      this.router.navigateByUrl('/workplace');
    }
  }

  configureAppLanguage(lang: any) {
    this.appLanguage = lang.value
    this.translate.use(lang.name)
    setLocalStorageItem('language', lang.name);
    this.selectedLang = lang.name;
    window.location.reload()
  }

  isLangSelected(lang: any): boolean {
    return lang.name === this.selectedLang;
  }

  logOut() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }

  goToEditProfile() {
    this.router.navigate(['/user-profile/edit-profile']);
  }
  goToUserProfile() {
    this.router.navigate(['/user-profile']);
  }

  selectLanguageTemplate(templateRef: any) {
    let dialogRef = this.dialog.open(templateRef, {
      width: '50%',
      height: '50%'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'confirm') {
        //do nothing
      }
    });
  }
}
