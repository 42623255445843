<div class="footer">
    <div class="footer-background ">
        <img [src]="imgConfiguration['SURVEY_APP.FOOTER_BG']" alt="">
    </div>

    <div class="footer-content container">
        <div class="footer-left">
            <a [routerLink]="['/home/welcome']">
            <img class="cursor-pointer" [src]="imgConfiguration['SURVEY_APP.ZENFORMS_APP_LOGO']" alt="" >
        </a>
        </div>
        <div class="footer-right d-flex">
            <div class="footer-list">
                <p class="assessment-text-semibold-14 assessment-text-grey-ten">{{'ORG.NAME'|translate}}</p>
                <ul class="pt-2">
                    <a *ngIf="!loggedIn" [routerLink]="['/login/select-organisation']"> <li class="assessment-text-regular-16">{{ 'SIGNUP' | translate }}</li> </a>

                    <li class="assessment-text-regular-16 ">{{'CREATE.FORM'|translate}}</li>
                    <a  *ngIf="loggedIn"  [routerLink]="['/workplace']"> <li class="assessment-text-regular-16">{{ 'MY.WORKSPACE' | translate }}</li> </a>
                    <li class="assessment-text-regular-16 ">{{'PRICING'|translate}}</li>
                    <li class="assessment-text-regular-16 ">{{'TEMPLATES'|translate}}</li>
                </ul>
            </div>
            <div class="footer-list">
                <p class="assessment-text-semibold-14 assessment-text-grey-ten">{{'USE.CASES'|translate}}</p>
                <ul class="pt-2">
                    <li class="assessment-text-regular-16 ">{{'SURVEY'|translate}}</li>
                    <li class="assessment-text-regular-16 ">{{'ASSESSMENTS'|translate|titlecase}}</li>
                    <li class="assessment-text-regular-16 ">{{'M&E'|translate}}</li>
                    <li class="assessment-text-regular-16 ">{{'QUIZ'|translate}}</li>
                    <li class="assessment-text-regular-16 ">{{'POLL'|translate}}</li>
                </ul>
            </div>
            <div class="footer-list">
                <p class="assessment-text-semibold-14 assessment-text-grey-ten">{{'COMPANY'|translate}}</p>
                <ul class="pt-2">
                    <li class="assessment-text-regular-16 ">{{'ABOUT.US'|translate}}</li>
                    <li class="assessment-text-regular-16 ">{{'TEAM'|translate}}</li>
                    <li class="assessment-text-regular-16 ">{{'PARTNERS'|translate}}</li>
                </ul>
            </div>
            <div class="footer-list d-none d-md-block">
                <p class="assessment-text-semibold-14 assessment-text-grey-ten">{{'SUPPORT'|translate|uppercase}}</p>
                <ul class="pt-2">
                    <li class="assessment-text-regular-16 ">{{'CONTACT.US'|translate|titlecase}}</li>
                    <li class="assessment-text-regular-16 ">{{'HELP'|translate}}</li>
                </ul>
            </div>
        </div>
    </div>
    <hr class="footer-hr d-md-none">

        <div class="d-flex justify-content-between container d-md-none">
            <div *ngIf="newLangObjList" class="lang">
                <div class="dropdown" [ngClass]="{'open-up': isDropdownVisible}">
                    <button class="p-2 web-button trigger dropbtn d-flex align-items-center " (click)="toggleDropdown()"
                        data-toggle="dropdown" data-auto-close="outside">
                        <img [src]="imgConfiguration['SURVEY_APP.WEB_ICON']" class="w-100" alt="">
                        <span class="mx-1">{{ selectedLanguage | titlecase }}</span>
                        <img [src]="imgConfiguration['SURVEY_APP.ARROW_UP']" alt="Dropdown">
                    </button>
                    <div class="dropdown-content" *ngIf="isDropdownVisible">
                        <div class="custom-style-pop-up p-3">
                            <div class="mt-1">
                                <div class="mt-1" *ngFor="let lang of newLangObjList"
                                    (click)="configureAppLanguage(lang); toggleDropdown()">
                                    <span class="assessment-text-regular-16 cursor-pointer"
                                        [ngClass]="appLanguage==lang.value?'assessment-text-primary-one':'assessment-text-grey-ten'">{{lang.value
                                        | titlecase}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="support">
                <span class="assessment-text-regular-16 ">{{'CONTACT.US'|translate|titlecase}}</span>
                <span class="horizontal-divider"></span>
                <span class="assessment-text-regular-16 ms-3">{{'HELP'|translate}}</span>
            </div>
        </div>
        <hr class="footer-hr">
        
        <div class="footer-bottom d-flex justify-content-between pb-4 container">
            <div *ngIf="newLangObjList" class="user-language ms-2 d-none d-md-block">
                <div class="dropdown" [ngClass]="{'open-up': isDropdownVisible}">
                    <button class="p-2 web-button trigger dropbtn d-flex align-items-center " (click)="toggleDropdown()"
                        data-toggle="dropdown" data-auto-close="outside">
                        <img [src]="imgConfiguration['SURVEY_APP.WEB_ICON']" class="w-100" alt="">
                        <span class="selected-lang assessment-text-regular-16 assessment-text-grey-ten">{{ selectedLanguage
                            | titlecase }}</span>
                        <img [src]="imgConfiguration['SURVEY_APP.ARROW_UP']" alt="Dropdown">
                    </button>
                    <div class="dropdown-content" *ngIf="isDropdownVisible">
                        <div class="custom-style-pop-up p-3">
                            <div class="mt-1">
                                <div class="mt-1" *ngFor="let lang of newLangObjList"
                                    (click)="configureAppLanguage(lang); toggleDropdown()">
                                    <span class="assessment-text-regular-16 cursor-pointer"
                                        [ngClass]="appLanguage==lang.value?'assessment-text-primary-one':'assessment-text-grey-ten'">{{lang.value
                                        | titlecase}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        <div class="terms-privacy">
            <p class="assessment-text-regular-16 cursor-pointer">{{'TERMS.CONDITIONS'|translate}} |
                {{'PRIVACY.POLICY'|translate}}</p>
        </div>
        
        <div class="copyright">
            <p class="assessment-text-regular-16">{{'ALL.RIGHTS.RESERVED'|translate}}</p>
        </div>
    </div>
</div>