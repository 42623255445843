import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { HeaderComponent } from './components/header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ImgUrlConverterComponent } from './utility-components/img-url-converter/img-url-converter.component';
import { SuccessDialogComponent } from './utility-components/success-dialog/success-dialog.component';
import { SearchComponent } from './utility-components/search/search.component';
import { MaterialModules } from './ng-material-alias/material-modules';
import { HoverDroperComponent } from './utility-components/hover-droper/hover-droper.component';
import { PreviewPageComponent } from './utility-components/preview-page/preview-page.component';
import { MsgRollerComponent } from './utility-components/msg-roller/msg-roller.component';
import { HeaderExtensionComponent } from './components/header-extension/header-extension.component';
import { ImgCrafterComponent } from './utility-components/img-crafter/img-crafter.component';
import { MlTranslatePipe } from './pipes/ml-translate.pipe';
import { NewFormPopupComponent } from './components/new-form-popup/new-form-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FooterComponent } from './components/footer/footer.component';
import { MlTextPipe } from './pipes/ml-text.pipe';
import { FormModeSelectorComponent } from './utility-components/form-mode-selector/form-mode-selector.component';
import { SessionExpiredComponent } from './components/session-expired/session-expired.component';
import { ChildQuestionPreviewComponent } from './components/child-question-preview/child-question-preview.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ImgUrlConverterComponent,
    SuccessDialogComponent,
    SearchComponent,
    HoverDroperComponent,
    PreviewPageComponent,
    MsgRollerComponent,
    HeaderExtensionComponent,
    ImgCrafterComponent,
    MlTranslatePipe,
    NewFormPopupComponent,
    FooterComponent,
    MlTextPipe,
    FormModeSelectorComponent,
    SessionExpiredComponent,
    ChildQuestionPreviewComponent
    
  ],    
  imports: [
    CommonModule,
    SharedRoutingModule,
    MaterialModules,
    FormsModule,
    TranslateModule,
    MatDialogModule,
    
  ],
  exports:[
    HeaderComponent,
    MaterialModules,
    SearchComponent,
    HoverDroperComponent,
    PreviewPageComponent,
    HeaderExtensionComponent,
    ImgCrafterComponent,
    MlTranslatePipe,
    MlTextPipe,
    MsgRollerComponent,
    NewFormPopupComponent,
    MatDialogModule,
    FooterComponent,
    FormModeSelectorComponent,
    ChildQuestionPreviewComponent
  ]
})
export class SharedModule { }
