<main class="container signUp-overlay col-12 col-lg-5 col-xl-4">
    <div class="py-4" >
        
        <div *ngIf="isSignIn">
            <app-connect-crafter (switch)="switch($event)"></app-connect-crafter>
        </div>

        <div *ngIf="forgotPassword">
            <div class="">
                <a (click)="switch('signin')" class="text-decoration-none cursor-pointer">
                    <img [src]="imgConfiguration['SURVEY_APP.SINGLE_LEFT_ICON']" alt="left arrows">
                    <span
                        class="assessment-text-semibold-16 assessment-text-grey-ten ms-3">{{'AUTH.BUTTON.FORGOT_PASSWORD.RESET_PASSWORD' | translate}}</span>
                </a>
            </div>
            <app-forgot-password-modal (switch)="switch($event)"></app-forgot-password-modal>
        </div>
        <div *ngIf="otpValidation">
            <div class="">
                <p class="assessment-text-bold-22 assessment-text-grey">
                    {{'APPLICATION_PROCESS.TEXT.STEP_ONE_VERIFY' | translate}}
                </p>
            </div>
            <app-otp-validation-modal [data]="data"></app-otp-validation-modal>
        </div>
    </div>
</main>