import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { getLocalStorageItem, setLocalStorageItem } from '../shared/utility/utility';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  getAssessmentData: any;
  UserIconPicEditProfile: any;
  private toggleSubject = new BehaviorSubject<number>(0);
  imgDrafter :any;


  setImage(data:any){
    this.imgDrafter=data
  }

  getImage(){
    return this.imgDrafter
  }

  getDomainsForUser(id: any) {
    throw new Error('Method not implemented.');
  }
  uploadProfileImage(selectedFile: File) {
    throw new Error('Method not implemented.');
  }

  getImagesData(): any {
    throw new Error('Method not implemented.');

  }
  commonsReportDataSet = 'commons-report-service';
  commonsDomainSerivce = 'commons-domain-service'
  private data = new BehaviorSubject(false);
  data$ = this.data.asObservable();
  profileProjector = new BehaviorSubject({});

  constructor(private userHttpClient: HttpClient) { }

  getCompanyName(){
    return this.userHttpClient.get(`${environment.baseUrl}/${this.commonsReportDataSet}/api/v1/datasets/name/ASSESSMENT_ORG-GetCompanyName/execute`);
  }

  fetchCustomJson(url: any) {
    return this.userHttpClient.get('assets/json/' + url + '.json')
  }

  getSubDomain(data:any): Observable<any> {
    return this.userHttpClient.get(`${environment.baseUrl}/${this.commonsDomainSerivce}/api/v1/domains/sub-domains?context=${data['context']}&page=0&parentDomainCode=${data.domainCode}&size=50`);
  }

  isAdmin() {
    return this.userHttpClient.get(`${environment.baseUrl}/commons-iam-service/api/v1/user/verificaton/`)
  }

  checkApprovalStatus(){
    if(!(getLocalStorageItem('approved') && getLocalStorageItem('approved')=='true')){
      this.getUserApprovalStatus().subscribe({
        next:(status:any)=>{
          setLocalStorageItem('approved',status)
        },
        error:(err:any)=>{
          throw err
        }
      })
    } 
    
  }

  getUserApprovalStatus(){
    return this.userHttpClient.get(`${environment.baseUrl}/commons-iam-service/api/v1/user/verificaton/`)
  }

 
  getPendingUserList(page: number, size: number, status: string): Observable<any[]> {
    const url = `${environment.baseUrl}/${this.commonsReportDataSet}/api/v3/datasets/name/ASSESSMENT_ORG.GetUserVerification/execute?page=${page}&size=${size}&params=STATUS%3D${status}`;
    return this.userHttpClient.get<any[]>(url);
  }
  
  
  pendingUserCount(status: string) {
    return this.userHttpClient.get(`${environment.baseUrl}/${this.commonsReportDataSet}/api/v3/datasets/name/ASSESSMENT_ORG.GetUserVerificationCount/execute?page=0&params=STATUS%3D${status}&size=100`)
  }
  


  approveOrRejectUsers(userIds: string[], remark: string): Observable<any> {
    const remarks = remark;
    const userIdsString = userIds.join('&userId=');
  
    const url = `${environment.baseUrl}/commons-iam-service/api/v1/user/verificaton/${remark}?remarks=${remarks}&userId=${userIdsString}`;
  
    return this.userHttpClient.patch(url, null);
  }
  
  

  fetchAssessmentTypes(): Observable<any[]> {
    return this.userHttpClient.get<any[]>(`${environment.baseUrl}/assessment-service/api/v1/ref-data/class/REF.ASSESSMENT_TYPE?direction=ASC&page=1&size=100`);
  }

  fetchQuestionTypes(): Observable<any[]> {
    return this.userHttpClient.get<any[]>(`${environment.baseUrl}/assessment-service/api/v1/ref-data/class/REF.QUESTION_TYPE?direction=ASC&page=1&size=100`);
  }


  setToggleValue(value: number) {
    this.toggleSubject.next(value);
  }

  getToggleValue(): Observable<number> {
    return this.toggleSubject.asObservable();
  }

  createParams(params: { [key: string]: any }): HttpParams {
    let param = new HttpParams();
    for (const key of Object.keys(params)) {
      param = param.set(key, params[key]);
    }
    return param;
  }

  createHeaders(headers: any): HttpHeaders {
    let header = new HttpHeaders();
    for (const key of Object.keys(headers)) {
      header = header.append(key, headers[key]);
    }
    return header;
  }


  changeData(data: boolean) {
    this.data.next(data)
  }


  getAssessments(data: any) {
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsReportDataSet + '/api/v3/datasets/name/ASSESSMENT_ORG-GetAssessmentForDomain/execute?page=' + data.page + '&size=' + data.size + '&params=DOMAIN_CODE=' + data.domain + '--LANGUAGE=' + data.lang + '--USER_INFO=' + data.userId + '--IN_PARAM_FORM_TYPE=' + data.formType + '--IN_PARAM_SUB_DOMAIN=' + data.subDomains + '--SORT_BY=' + data.sortby + '--CONTEXT='+data.context)
  }


  getQuestions(data: any) {
    return this.userHttpClient.get(environment.baseUrl +
      '/commons-report-service/api/v3/datasets/name/ASSESSMENT_ORG-QuestionOfAssessmentForDomain/execute?page=' + data.pageNum + '&params=DOMAIN_CODE=' + data.domain + '--LIMIT_ROW_COUNT=' + data.rowcount + '--LANGUAGE=' + data.lang + '--USER_INFO=' + data.userId + '--IN_PARAM_SUB_DOMAIN=' + data.selectedSubs + `--IN_PARAM_QUESTION_TYPE=` + data.selectedQuesTypes + `--SORT_BY=` + data.sort + '&size=12');
  }



  getAssessmentCount(data: any) {
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsReportDataSet + '/api/v3/datasets/name/ASSESSMENT_ORG-GetAssessmentForDomainCount/execute?page=' + data.page + '&size=' + data.size + '&params=DOMAIN_CODE=' + data.domain + '--LANGUAGE=' + data.lang + '--USER_INFO=' + data.userId+ '--SORT_BY='+data.sortby+'--CONTEXT='+data.context)
  }


  getQuestionCount(domain: any, page: number, size: number, language: string,userId: number) {
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsReportDataSet + '/api/v3/datasets/name/ASSESSMENT_ORG-QuestionOfAssessmentForDomainCount/execute?page=' + page + '&size=' + size + '&params=DOMAIN_CODE=' + domain + '--LANGUAGE=' + language + '--USER_ID=-1' + '--USER_INFO='+userId)
  }
  getForms(page: number, size: number) {
    return this.userHttpClient.get(environment.baseUrl + '/assessment-service/api/v1/assessmentinstances?page=' + page + '&size=' + size, { observe: 'response' });
  }

  getDomains(data: any): Observable<any> {
    let apiUrl = `${environment.baseUrl}/${this.commonsReportDataSet}/api/v3/datasets/name/ASSESSMENT_ORG-GetDomainAssessment/execute?page=${data.page}&size=${data.size}&params=CONTEXT=${data.context}`;
  
    if (data.search) {
      apiUrl += `--SEARCH_TEXT=${encodeURIComponent(data.search)}`;
    }
    return this.userHttpClient.get(apiUrl, { observe: 'response' });
  }
  
  getOverallStats(domain: any, page: number, size: number) {
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsReportDataSet + '/api/v3/datasets/name/ASSESSMENT_ORG-GetAssessmentDetail/execute?page=' + page + '&size=' + size + '&params=DOMAIN_CODE=' + domain)
  }
  getTopContributors(domain: any, page: number, size: number) {
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsReportDataSet + '/api/v3/datasets/name/ASSESSMENT_ORG-GetTopContributers/execute?page=' + page + '&size=' + size + '&params=DOMAIN_CODE=' + domain)
  }

  getPopularAssessments(domain: any, page: number, size: number) {
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsReportDataSet + '/api/v3/datasets/name/ASSESSMENT_ORG-GetFeaturedAssessment/execute?page=' + page + '&size=' + size + '&params=DOMAIN_CODE=' + domain + `--LANGUAGE=ENG`)
  }


  getDomainsAPI(config: any): Observable<any> {
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsDomainSerivce + '/api/v1/domains?context='+config.context+'&isRoot=true&page=' + config.page + '&size=' + config.size,
      { observe: 'response' })
  }

  getSubDomains(depth: number, page: number, size: number, domain: string,data:any): Observable<any> {
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsDomainSerivce + '/api/v1/domains/sub-domains?context='+data['context']+'&depth=' + depth + '&page=' + page + '&size=' + size + '&parentDomainCode=' + domain,
      { observe: 'response' })
  }

  getPopularData(page: number, size: number) {
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsReportDataSet + '/api/v3/datasets/name/ASSESSMENT_ORG-GetFeaturedAssessment/execute?page=' + page + '&params=DOMAIN_CODE=-1' + `--LANGUAGE=ENG` + `&size=` + size);
  }

  getTopContributorscards(page: number, size: number) {
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsReportDataSet + '/api/v3/datasets/name/ASSESSMENT_ORG-GetTopContributers/execute?page=' + page + '&params=DOMAIN_CODE=-1--LIMIT_ROW_COUNT1--LIMIT_OFFSET=0&size=' + size);
  }
  getAllcontributors(page: number, size: number) {
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsReportDataSet + '/api/v3/datasets/name/ASSESSMENT_ORG-GetTopContributers/execute?page=' + page + '&params=DOMAIN_CODE=-1--LIMIT_ROW_COUNT1--LIMIT_OFFSET=0&size=' + size,{ observe: 'response' });
  }

  getCount(count: number, page: number, size: number) {
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsReportDataSet + '/api/v3/datasets/name/ASSESSMENT_ORG-GetTopContributersCount/execute?page=' + page + '&params=DOMAIN_CODE=-1--LIMIT_ROW_COUNT1--LIMIT_OFFSET=0&size=' + size, { observe: 'response' });
  }

  getUserData() {
    const apiUrl = environment.tldURL + '/ctld/api/tenant/user/v1';
    return this.userHttpClient.get(apiUrl);
  }

  fetchProfileProjector(data: any) {
    this.profileProjector.next(data)
  }

  fetchUserProfile() {
    this.checkApprovalStatus()
    if(environment.defaultOnwer!='echo'){
      this.getUserDataOrd().subscribe({
        next:(response: any) => {
          this.fetchProfileProjector(response)
          setLocalStorageItem('userId', response.id)
        },error:err=>{
          throw err
        }
      })
    }
  }

  getUserDataOrd() {
    const apiUrl = environment.tldURL + '/ctld/api/tenant/user/v1';
    return this.userHttpClient.get(apiUrl);
  }


  updateUserData(updatedUserData: any): Observable<any> {
    const apiUrl = environment.tldURL + '/ctld/api/tenant/user/v1';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.userHttpClient.patch<any>(apiUrl, updatedUserData, httpOptions);
  }


  getStatesData(): Observable<any> {
    const apiUrl = environment.tldURL + '/ctld/api/globalrefdata/v1/data?criteria=globalRefClass.classCode%20in%20(%27GREF.REGION_STATE%27,%27GREF.REGION_UNION_TERRITORY%27)%20and%20language.languageCode=%27ENG%27';
    return this.userHttpClient.get(apiUrl);
  }

  getCitiesByState(selectedState: string): Observable<any> {
    const apiUrl = environment.tldURL + `/ctld/api/globalrefdata/v1?parentRefData=REGION_STATE.${selectedState}&refClass=GREF.REGION_CITY&parentRefClass=GREF.REGION_STATE`;

    return this.userHttpClient.get(apiUrl);
  }

  // getAssessmentDataWithUserId(data: any, userId: string): Observable<any> {
  //   const params = this.createParams({
  //     DOMAIN_CODE: 
  //     LANGUAGE: data.lang,
  //     USER_ID: userId
  //   });

  //   const apiUrl = `${environment.baseUrl}/${this.commonsReportDataSet}/api/v3/datasets/name/ASSESSMENT_ORG-GetAssessmentForDomain/execute?page=${data.page}&size=${data.size}&params=${params.toString()}`;

  //   return this.userHttpClient.get(apiUrl);
  // }




  uploadProfileImageuser(selectedFile: File, userId: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', selectedFile);

    const uploadUrl = environment.tldURL + '/ctld/api/tenant/user/v1/' + userId + '/logo';

    return this.userHttpClient.post<any>(uploadUrl, formData)
      .pipe(
        catchError((error: any) => {
          console.error('Error uploading profile image:', error);
          return throwError(error);
        })
      );
  }
  getAssessmentUserDomainAssessment(): Observable<any> {
    const datasetName = 'ASSESSMENT_ORG-USER_DOMAIN_ASSESSMENT';
    const apiUrl = `${environment.baseUrl}/commons-report-service/api/v1/datasets/name/${datasetName}/execute`;

    return this.userHttpClient.get(apiUrl);
  }

  getUserQuestions(language: string, domainCode: string): Observable<any> {
    const datasetName = 'ASSESSMENT_ORG-UserQuestion';
    const params = `LANGUAGE=${language}--DOMAIN_CODE=${domainCode}`;
    const apiUrl = `${environment.baseUrl}/commons-report-service/api/v1/datasets/name/${datasetName}/execute?params=${params}`;

    return this.userHttpClient.get(apiUrl);
  }

  getUserMyforms(language: string, domainCode: string): Observable<any> {
    const datasetName = 'ASSESSMENT_ORG-UserAssessmentAnalytics';
    const params = `LANGUAGE=${language}--DOMAIN_CODE=${domainCode}`;
    const apiUrl = `${environment.baseUrl}/commons-report-service/api/v1/datasets/name/${datasetName}/execute?params=${params}`;

    return this.userHttpClient.get(apiUrl);
  }



  getUserQuestionsCount(language: string, domainCode: string): Observable<any> {
    const datasetName = 'ASSESSMENT_ORG-UserQuestionCount';
    const params = `LANGUAGE=${language}--DOMAIN_CODE=${domainCode}`;
    const apiUrl = `${environment.baseUrl}/commons-report-service/api/v1/datasets/name/${datasetName}/execute?params=${params}`;
    return this.userHttpClient.get(apiUrl);
  }

  getUserMyformsCount(language: string, domainCode: string): Observable<any> {
    const datasetName = 'ASSESSMENT_ORG-UserAssessmentAnalyticsCount';
    const params = `LANGUAGE=${language}--DOMAIN_CODE=${domainCode}`;
    const apiUrl = `${environment.baseUrl}/commons-report-service/api/v1/datasets/name/${datasetName}/execute?params=${params}`;
    return this.userHttpClient.get(apiUrl);
  }

  getSearchedSkills(data:any): Observable<any> {
    let url=''
    if(data['text'] && data['text'].length){
      url='&domainCode='+data.domain+'&page=0&size=100&text='+data['text']
    }else{
      url='&domainCode='+data.domain+'&page=0&size=100'
    }
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsDomainSerivce + '/api/v1/skills/search/domain/parent/skills?context='+data['context']+url);
  }

  getSubSkills(data: any): Observable<any> {
    let url='';
    if(data['text'] && data['text'].length){
      url='&domainCode='+data.domain+'&page=0&size=100&parentSkill='+data.parentSkill+'&text='+data['text']
    }else{
      url='&domainCode='+data.domain+'&page=0&size=100&parentSkill='+data.parentSkill
    }
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsDomainSerivce + '/api/v1/skills/search/domain/child/skills?context='+data['context']+url);
  }

  getSkillDisplayer(ids:any){
    const resultList: string[] = ids.map((id:any) => `id=${id}`);

    const resultString: string = resultList.join('&');
    return this.userHttpClient.get(environment.baseUrl + '/' + this.commonsDomainSerivce + '/api/v1/skills/domain/skills/hierarchy?'+resultString);
  }

  postServiceBuilder(api: string, params: any, body: any, headers: any, extraParam?: any) {
    if (params != null) {
      return this.postCustomService(api, body, this.createHeaders(headers), extraParam, this.createParams(params));
    }
    return this.postCustomService(api, body, this.createHeaders(headers), extraParam);
  }

  postCustomService(apiName: string, body: any, header: HttpHeaders, observe?: any, params?: HttpParams): Observable<any> {
    let resp = {};
    if (observe !== undefined) {
      if (observe.hasOwnProperty('observeType')) {
        resp = {
          headers: header,
          // tslint:disable-next-line: no-string-literal
          observe: observe['observeType']
        };
      } else if (observe.hasOwnProperty('responseType')) {
        resp = {
          headers: header,
          // tslint:disable-next-line: no-string-literal
          responseType: observe['responseType'],
          observe: 'response'
        };
      }
    } else {
      resp = {
        headers: header,
        observe: 'response'
      };
    }
    return this.userHttpClient.post(environment.baseUrl + apiName + (params ? params : ''), body, resp);
  }
}

