<div *ngIf="!isDialogMode" class="left-portal" [ngStyle]="{'background-image':'url('+imgConfiguration['SURVEY_APP.LOGIN_BG']+')'}">
<div class="backdrop">

</div>
  <div class=" heading d-none d-md-flex flex-column position-absolute">
    <div class="subheading col-lg-11 col-xl-9 col-xxl-7">
      <p class="assessment-text-medium-16 subheading-one">{{'LOGIN.HEADING'|translate}}</p> 
      <hr class="login-hr opacity-3">
      <p class="assessment-text-medium-20 subheading-two opacity-8">{{'LOGIN.SUBHEADING'|translate}}
      </p>
    </div>
  </div>
</div>

<div class="sign-up-portal h-100 d-block position-fixed">
  <div class="portal-container align-items-center">
    <div class="top-img d-flex ">
      <a  [routerLink]="['/home/welcome']">
      <img class="top-company-logo w-100 cursor-pointer" [src]="imgConfiguration['SURVEY_APP.ZENFORMS_APP_LOGO']" alt="">
    </a>
    </div>
    <ng-container *ngIf="currentStep === 1">
      <div class="login-container mt-3">
        <div class="signIn">

          <p class="assessment-text-bold-36 assessment-text-grey-ten">{{'SIGN.IN'| translate}}</p>

          <div>
            <span class="assessment-text-semibold-18 assessment-text-grey-ten">{{'OR'|translate}} </span> <span
              class="create-acc cursor-pointer assessment-text-semibold-18"
              (click)="onCreateAccountClick()">{{'CREATE.AN.ACCOUNT'|translate}}</span>
          </div>

        </div>

        <form [formGroup]="loginForm" (submit)="login()">
          <div class="d-flex flex-column mt-4 email-label">
            <label class="assessment-text-medium-16 assessment-text-grey-ten" for="email">{{'EMAIL'| translate}}</label>
            <input type="text" class="input-box mt-2" formControlName="email" placeholder="Email / login">
          </div>

          <div *ngIf="showOrgFields" class="mt-4">
            <label class="assessment-text-medium-16" for="orgId">{{ 'SELECT.ORGANISATION' | translate }}</label>
            <select class="form-control mt-2 input-box" id="orgId" name="orgId" formControlName="orgId" required>
              <option value="">{{ 'SELECT.AN.ORG' | translate }}</option>
              <option *ngFor="let organization of organizations" [value]="organization.login">{{ organization.name }}
              </option>
            </select>
          </div>


          <div *ngIf="showPasswordFields" class="input-group flex-column mt-4">
            <label class="assessment-text-medium-16" for="password">{{'PASSWORD'|translate}}</label>
            <div class="d-flex align-items-end ">
              <input type="password" class="form-control input-box custom-box mt-2"
                [type]="passwordVisible ? 'text' : 'password'" placeholder="{{'PASSWORD'|translate}}" formControlName="password">

              <button type="button" class="btn btn-outline-light" id="togglePassword"
                (click)="togglePasswordVisibility()">
                <img [ngClass]="passwordVisible ? 'custom-icon-visible' : 'custom-icon-hidden'" aria-hidden="true"
                [src]="passwordVisible ? imgConfiguration['VIEW_PASSWORD_EYE_ICON'] : imgConfiguration['VIEW_PASSWORD_EYE_SLASH_ICON']"
                  alt="Eye Icon" width="23" height="16" />
              </button>
            </div>
          </div>

          <div class="mt-4">
            <button type="submit" class="btn confirm-btn main-btn w-100" [disabled]="inProgress">
              <div class="spinner-border spinner-border-sm text-light  me-2" role="status" *ngIf="inProgress">
                <span class="visually-hidden"></span>
              </div>
              <span class="custom-button assessment-text-medium-18">{{'SIGN.IN' |translate}}</span>
            </button>
          </div>

        </form>
        <div class="mt-2 forgot-password-container d-flex justify-content-end">
          <button class="forgot-password btn-outline-shade-4 assessment-text-medium-14 opacity-8"
            (click)="forgotPassword()">{{'AUTH.BUTTON.FORGOT_PASSWORD'|translate}}</button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="currentStep === 2">
      <p class="assessment-text-regular-12 assessment-text-grey-eight cursor-pointer mt-3" (click)="goBack()">
        <img [src]="imgConfiguration['SURVEY_APP.SINGLE_LEFT_ICON']" alt="" class="me-1">
        {{ 'GO.BACK' | translate }}
      </p>
      <p class="assessment-text-grey-nine assessment-text-regular-16 mt-5">Your account is currently inactive<br> Please
        enter otp to activate</p>

      <form [formGroup]="loginForm" (submit)="activateAccount()">
        <div class="otp-input d-flex align-items-center flex-column mt-5">
          <input type="tel" name="otp" aria-label="OTP"
            class="form-control outline-none border-0 assessment-text-semibold-27 bg-transparent text-center ng-pristine ng-invalid ng-touched"
            placeholder="OTP" maxlength="6" oninput="this.value=this.value.replace(/[^0-9]/g,'');"
            formControlName="enteredOtp">

          <button type="submit" class="btn confirm-btn main-btn w-100 mt-5" [disabled]="inProgress">
            <div class="spinner-border spinner-border-sm text-light  me-2" role="status" *ngIf="inProgress">
              <span class="visually-hidden"></span>
            </div>
            <span class="custom-button assessment-text-medium-18">{{'VERIFY' | translate}}</span>
          </button>
        </div>
      </form>
    </ng-container>

    <ng-container *ngIf="currentStep === 3">
      <p class="assessment-text-regular-12 assessment-text-grey-eight cursor-pointer mt-3" (click)="goBack()">
        <img [src]="imgConfiguration['SURVEY_APP.SINGLE_LEFT_ICON']" alt="" class="me-1">
        {{ 'GO.BACK' | translate }}
      </p>
      <p class="assessment-text-regular-16 mt-5">{{'SELECT.AN.ORG'|translate}}</p>

      <form [formGroup]="loginForm">
        <div class="d-flex flex-column mt-4 email-label">
          <label class="assessment-text-medium-16 assessment-text-grey-ten" for="email">{{ 'EMAIL' | translate
            }}</label>
          <input type="text" class="input-box mt-2" formControlName="email" placeholder="Email / login">
        </div>

        <div class="mt-4" *ngIf="loginForm.get('email')!.value && organizations.length > 0; else noOrgsError">
          <label class="assessment-text-medium-16" for="orgId">{{ 'SELECT.ORGANISATION' | translate }}</label>
          <select class="form-control mt-2 input-box" id="orgId" name="orgId" formControlName="orgId" required>
            <option value="">{{ 'SELECT.AN.ORG' | translate }}</option>
            <option *ngFor="let organization of organizations" [value]="organization.login">
              {{ organization.name }}
            </option>
          </select>
        </div>

        <ng-template #noOrgsError>
          <p class="assessment-text-medium-16 text-danger">{{'NO.ORG.EMAIL'|translate}}</p>
        </ng-template>


        <button *ngIf="!showPasswordInput" class="btn confirm-btn main-btn w-100 mt-4" [disabled]="inProgress"
          (click)="checkActiveStatus()">
          <div class="spinner-border spinner-border-sm text-light me-2" role="status" *ngIf="inProgress">
            <span class="visually-hidden"></span>
          </div>
          <span class="custom-button assessment-text-medium-18">{{ 'SEND.OTP' | translate }}</span>
        </button>
      </form>

      <form *ngIf="showPasswordInput" [formGroup]="loginForm" (submit)="resetPassword()">
        <div class="d-flex flex-column mt-4">
          <label class="assessment-text-medium-16 assessment-text-grey-ten" for="otp">{{'LOGIN.ENTER.OTP'|translate}}</label>
          <input type="text" class="input-box mt-2" id="otp" formControlName="otp" placeholder="OTP"
            (input)="validateNumber($event)">
        </div>

        <div class="d-flex flex-column mt-4">
          <label class="assessment-text-medium-16 assessment-text-grey-ten" for="newPassword">Enter New Password</label>
          <div class="input-group mt-2">
            <input type="{{ passwordVisible ? 'text' : 'password' }}" class="form-control input-box custom-box"
              id="newPassword" formControlName="newPassword" placeholder="{{'PASSWORD'|translate}}">
            <button type="button" class="btn btn-outline-light" (click)="togglePasswordVisibility()">
              <img [ngClass]="passwordVisible ? 'custom-icon-visible' : 'custom-icon-hidden'" aria-hidden="true"
                [src]="passwordVisible ? imgConfiguration['VIEW_PASSWORD_EYE_ICON'] : imgConfiguration['VIEW_PASSWORD_EYE_SLASH_ICON']"
                alt="Eye Icon" width="23" height="16" />
            </button>
          </div>
        </div>
        
        <button type="submit" class="btn confirm-btn main-btn w-100 mt-4"  [disabled]="inProgress">
          <div class="spinner-border spinner-border-sm text-light me-2" role="status" *ngIf="inProgress">
            <span class="visually-hidden"></span>
          </div>
          <span class="custom-button assessment-text-medium-18">{{ 'AUTH.BUTTON.FORGOT_PASSWORD.RESET_PASSWORD' | translate }}</span>
        </button>
      </form>

    </ng-container>
  </div>
</div>