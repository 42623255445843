import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-hover-droper',
  templateUrl: './hover-droper.component.html',
  styleUrls: ['./hover-droper.component.scss']
})
export class HoverDroperComponent implements OnInit {

  @Input() hoverableDropdownName:any;
  @Input() dataList:any;
  @Input() selectedFilter:any
  
  @Output() selectedItem = new EventEmitter<any>();
  imgConfiguration: any;
  constructor(
    private mainService: MainService
  ) { }

  ngOnInit(): void {   
    this.imgConfiguration=this.mainService.getImage()
  }

  onSelectItem(item:any){
    if(this.selectedFilter!=item.value){
      this.selectedFilter=item.value
      this.selectedItem.emit(item);
    }
   
  }

}
