<div class=" preview-view " [ngStyle]="{'background-image':'url('+imgConfiguration['SURVEY_APP.WHIRL_ILLUSTRATION']+')'}">
  <div class="container  d-flex justify-content-center" *ngIf="languageForm">
    <div class="col-12 col-md-10 my-5">
      
      <div class="header-info-container mx-3 mt-2">
        <div class="img-box" *ngIf="languageForm['imageURL']">
          <img class="object-fit-cover" [src]="languageForm['imageURL']">
        </div>
        <div class="d-flex flex-column  p-4">
          <span
            class="assessment-text-grey-ten assessment-text-bold-24">{{languageForm.assessment.assessmentName}}</span>
          <div class="mt-2" *ngIf="languageForm.assessment['subDomain']">
            <span
              class="btn-shade-5 py-1 px-2 assessment-text-medium-10 space-small" *ngIf="appConfig['SURVEY_APP_CONFIG.HIDE_SUB_DOMAIN']!=='1'">{{splitAtLast(languageForm.assessment['subDomain'])}}</span>
          </div>
          <!-- <div class="mt-3">
            <span class="assessment-text-grey-nine assessment-text-regular-12">{{'CREATED.BY'|translate}}<span
                class="assessment-text-palette-eighty input-docoration-underline camel-case">{{languageForm['author']}}</span>
              {{'IN'|translate}} <a
                class="url-highlighter-1 assessment-text-palette-eighty input-docoration-underline" [routerLink]="['/home/domain', languageForm.assessment['domain']?.split('.').pop().toLowerCase(), 'assessment']">{{getPascalCase(languageForm.assessment['domain']?.split('.').pop())}}</a>
              {{'DOMAIN'|translate|lowercase}}</span>
          </div> -->
          <div class="divider-horizontal-line w-100 my-3"></div>
          <span class="mt-2 desc-line-ht assessment-text-grey-nine  assessment-text-regular-14"
            [innerHTML]="languageForm.assessment.assessmentDesc"></span>
        </div>
      </div>
      <section class="form-container mx-3 mt-5 p-3 p-md-5">
        <div class="form-container-inner  ">
          <div class="mb-5" *ngFor="let section of languageForm.questionPaperSectionList">
            <div class="mb-3">
              <span class="assessment-text-grey-ten assessment-text-bold-17">{{section.qpsName}}</span>
            </div>
            <div class=" mb-4 pb-2" *ngFor="let block of section.sectionQuestionsList;let j=index">
              <div class="d-flex flex-row ">
                <span class="me-2 assessment-text-grey-nine assessment-text-bold-14">{{j+1}}. </span>
                <span
                  class="assessment-text-grey-nine assessment-text-bold-14">{{block.question.questionText}}</span>
              </div>

              <section class="mt-2">
                <ng-container *ngIf="block.question.questionType.code ==='QUESTION_TYPE.SUBJECTIVE_SHORT'">
                  <div class="col-6 assessment-text-grey-nine assessment-text-regular-14  ques-type-container d-flex justify-content-between">
                    <span>{{'YOUR.ANSWER'|translate}}</span>
                    <span>{{'SHORT.TXT.LIMIT'|translate}}</span>
                  </div>

                </ng-container>

                <ng-container *ngIf="block.question.questionType.code ==='QUESTION_TYPE.SUBJECTIVE_LONG'">
                  <div class="col-6  assessment-text-grey-nine assessment-text-regular-14 ques-type-container d-flex justify-content-between">
                    <span>{{'YOUR.ANSWER'|translate}}</span>
                    <span>{{'SHORT.TXT.LIMIT'|translate}}</span>
                  </div>

                </ng-container>

                <ng-container *ngIf="block.question.questionType.code ==='QUESTION_TYPE.DATE'">
                  <div class="col-6  assessment-text-grey-nine assessment-text-regular-14 ques-type-container d-flex justify-content-between">
                    <span>{{'YOUR.ANSWER'|translate}}</span>
                    <span>{{'DATE-FORMAT'|translate}}</span>
                  </div>

                </ng-container>

                <ng-container *ngIf="block.question.questionType.code ==='QUESTION_TYPE.NUMBER'">
                  <div class="col-6 assessment-text-grey-nine assessment-text-regular-14  ques-type-container d-flex justify-content-between">
                    <span>{{'YOUR.ANSWER'|translate}}</span>

                  </div>

                </ng-container>

                <ng-container *ngIf="block.question.questionType.code ==='QUESTION_TYPE.FILE_UPLOAD'">
                  <div class="col-6 assessment-text-grey-nine assessment-text-regular-14  ques-type-container d-flex justify-content-between">

                    <span>{{'UPLOAD.FILE'|translate}}</span>

                  </div>

                </ng-container>

                <ng-container *ngIf="block.question.questionType.code ==='QUESTION_TYPE.SCALE_RATING'">
                  <div class="col-11 assessment-text-grey-nine assessment-text-regular-14 d-flex flex-column ps-4">

                    <div class="d-flex w-100">
                      <div class="  d-flex w-100 justify-content-between">
                        <ng-container
                          *ngFor="let opt of block.question.defaultOptionsList; let k=index let first = first; let last = last">
                          <div class="circle">
                            <span class="circle__content">{{k+1}}</span>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="d-flex  flex-row justify-content-between ">
                      <ng-container
                        *ngFor="let opt of block.question.defaultOptionsList;  let k=index let first = first; let last = last">
                        <ng-container *ngIf="first || last ; else space">
                          <p class="assessment-text-grey-nine assessment-text-regular-14" *ngIf="first" style="margin-top: 1rem;">{{'VERY.UNLIKELY'|translate}}</p>
                          <p class="assessment-text-grey-nine assessment-text-regular-14" *ngIf="last" style="margin-top: 1rem;">{{'VERY.LIKELY'|translate}}</p>
                        </ng-container>
                        <ng-template #space>
                          <span>&nbsp;</span>
                        </ng-template>
                      </ng-container>
                    </div>

                  </div>

                </ng-container>

                <ng-container *ngIf="block.question.questionType.code === 'QUESTION_TYPE.OBJECTIVE'">
                  <div class="ms-4 d-flex flex-column"
                    *ngFor="let opt of block.question.defaultOptionsList ; let k=index">
                    <div class="d-flex flex-row mt-2">
                      <img class="icon-size me-3" [src]="imgConfiguration['SURVEY_APP.RADIO_ICON']">
                      <span class="assessment-text-grey-nine assessment-text-regular-14">{{opt.options.optionName}}</span>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="block.question.questionType.code === 'QUESTION_TYPE.MULTISELECT'">
                  <div class="ms-4 d-flex flex-column"
                    *ngFor="let opt of block.question.defaultOptionsList ; let k=index">
                    <div class="d-flex flex-row mt-2">
                      <div class=" me-3 check-box"></div>
                      <span class="line-cover assessment-text-grey-nine assessment-text-regular-14">{{opt.options.optionName}}</span>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="block.question.questionType.code === 'QUESTION_TYPE.OBJECTIVE_SUBJECTIVE'">
                  <div class="ms-4 d-flex flex-column"
                    *ngFor="let opt of block.question.defaultOptionsList ; let k=index">
                    <div class="d-flex flex-row mt-2">
                      <img class="icon-size me-3" [src]="imgConfiguration['SURVEY_APP.RADIO_ICON']">
                      <span class="assessment-text-grey-nine assessment-text-regular-14">{{opt.options.optionName}}</span>
                    </div>
                  </div>
                  <div class="col-6 mt-3 assessment-text-grey-nine assessment-text-regular-14  ques-type-container d-flex justify-content-between">
                    <span>{{'YOUR.ANSWER'|translate}}</span>
                    <span>{{'SHORT.TXT.LIMIT'|translate}}</span>
                  </div>
                </ng-container>

                <ng-container *ngIf="block.question.questionType.code === 'QUESTION_TYPE.MULTISELECT_SUBJECTIVE'">
                  <div class="ms-4 d-flex flex-column"
                    *ngFor="let opt of block.question.defaultOptionsList ; let k=index">
                    <div class="d-flex flex-row mt-2">
                      <div class=" me-3 check-box"></div>
                      <span class="line-cover assessment-text-grey-nine assessment-text-regular-14">{{opt.options.optionName}}</span>
                    </div>
                  </div>
                  <div class="col-6 mt-3 assessment-text-grey-nine assessment-text-regular-14 ques-type-container d-flex justify-content-between">
                    <span>{{'YOUR.ANSWER'|translate}}</span>
                    <span>{{'SHORT.TXT.LIMIT'|translate}}</span>
                  </div>
                </ng-container>

                <ng-container *ngIf="block.question.questionType.code === 'QUESTION_TYPE.SINGLE_SELECT_GRID'">
                  <div class="matrix-container">
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th *ngFor="let col of block?.question?.gridData?.columns" class="assessment-text-grey-nine assessment-text-regular-14">{{ col.optionName[0].text }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of block?.question?.gridData?.rows">
                          <td class="assessment-text-grey-nine assessment-text-regular-14">{{ row.optionName[0].text }}</td>
                          <td *ngFor="let col of block?.question?.gridData?.columns">
                            <input 
                              type="radio" disabled
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-container>

                <ng-container *ngIf="block.question.questionType.code === 'QUESTION_TYPE.MULTISELECT_GRID'">
                  <div class="matrix-container">
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th *ngFor="let col of block?.question?.gridData?.columns" class="assessment-text-grey-nine assessment-text-regular-14">{{ col.optionName[0].text }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of block?.question?.gridData?.rows">
                          <td class="assessment-text-grey-nine assessment-text-regular-14">{{ row.optionName[0].text }}</td>
                          <td *ngFor="let col of block?.question?.gridData?.columns">
                            <input 
                              type="checkbox" disabled
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-container>

              </section>
            </div>
          </div>
          <div class="d-flex flex-column">
            <div class="divider-horizontal-line w-100 my-3"></div>
            <div class="d-flex justify-content-center mt-3">
              <button disabled class="btn-shade-12 px-5">{{'COMMON.SUBMIT'|translate}}</button>
            </div>
          </div>
        </div>
      </section>

    </div>

    <div class="d-flex flex-column my-5 align-items-end align-items-md-center custom-overlay-area" *ngIf="!isFeatureHidden">
      <div class="d-flex flex-column align-items-center justify-content-center">
        <button (click)="navigateOnClose()" class="mt-4 close-btn d-flex justify-content-center align-items-center zero-btn-properties">
          <div class="img-close-container d-flex justify-content-center align-items-center">
            <img class="w-100 h-100" [src]="imgConfiguration['SURVEY_APP.CLOSE_LARGE_ICON']">
          </div>
        </button>
        <span class="mt-1 assessment-text-grey-seven assessment-text-regular-12">{{'COMMON.CLOSE'|translate}}</span>
      </div>
    </div>
    
  </div>
</div>