import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { getLocalStorageItem, setLocalStorageItem } from '../../utility/utility';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { MainService } from 'src/app/services/main.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  appLanguage: any = getLocalStorageItem('language');
  newLangObjList: any;
  selectedLanguage: any = getLocalStorageItem('language');
  isDropdownVisible: boolean = false;
  @Input() loggedIn = true;
  imgConfiguration: any;



  constructor(public appService: AppConfigService,
    public translate: TranslateService,
    private authService: AuthService,
    private mainService: MainService

    )     
    { }

  ngOnInit(): void {
    this.imgConfiguration=this.mainService.getImage()
    this.fetchAppLangList();
    this.authService.getLoggedInStatus().subscribe((status) => {
      this.loggedIn = status;
    });
    let userAccess = getLocalStorageItem('access');
    if (userAccess == 'public') {
      this.loggedIn = false;
    } else if (userAccess == 'private') {
      this.loggedIn = true;
    }

  }
  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  fetchAppLangList() {
    this.appService.fetchAppLangList().subscribe({
      next: (response: any) => {
        const data: any = response;
        this.appLanguage = data[this.appLanguage];
        this.newLangObjList = Object.entries(data).map(([key, value]) => ({
          name: key,
          value: value
        }));
      },
      error: (error: any) => {
        console.error('Error fetching app language list:', error);
        throw error;
      }
    });
  }

  configureAppLanguage(lang: any) {
    this.selectedLanguage = lang.value;
    this.appLanguage = lang.value
    this.translate.use(lang.name)
    setLocalStorageItem('language', lang.name);
    window.location.reload()

  }
}
