import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { getSessionStorageItem, setSessionStorageItem, setSessionStorageItemEncrypt } from 'src/app/shared/utility/utility';
@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  constructor(private userHttpClient: HttpClient) { }

  createParams(params: { [key: string]: string }): HttpParams {
    let param = new HttpParams();
    for (const key of Object.keys(params)) {
      param = param.set(key, params[key]);
    }
    return param;
  }

  authenticateUser( userDetails: any) {
    const headers1 = new HttpHeaders()
      .set('stateless-authentication','true');
    return this.userHttpClient.post(environment.baseUrl + '/commons-iam-service/api/v1/obo/cross/login', userDetails, {headers:headers1, observe: 'response' })
  }

  crossTenancyChecker(aidURL: any): Observable<boolean> {
    return this.getCrossTenant(aidURL).pipe(
      switchMap((resp: any) => {
        console.log(resp);
        let crossTenant = resp[0].login;
        if (getSessionStorageItem('tenant-session-active',true) === crossTenant) {
          return of(true); // Condition met, return true immediately
        } else {
          // Make the second API call
          return this.getCrossTenantSession(crossTenant).pipe(
            map((tenantSession: any) => {
              let sessionIdAPP: any = tenantSession.headers.get('X-Sessionid');
              setSessionStorageItemEncrypt('cross-session-id', sessionIdAPP,true);
              setSessionStorageItem('tenant-session-active', crossTenant,true);
              return true; // Return true after handling the second API call
            }),
            catchError((err: any) => {
              console.log(err);
              return of(false); // Return false if there's an error in the second API call
            })
          );
        }
      }),
      catchError((err: any) => {
        console.log(err);
        return of(false); // Return false if there's an error in the first API call
      })
    );
  }
  
  

  createUser(param:any, signupUserDeatils:any, userAppDetailsForHeader:any) {
    let headers1 = new HttpHeaders(userAppDetailsForHeader)
    headers1=headers1.append('stateless-authentication','true')
    return this.userHttpClient.post(environment.baseUrl + '/commons-iam-service/api/v1/obo/register?' + this.createParams(param), signupUserDeatils, { headers: headers1, observe: 'response' })
  }


  activateOrReactivateUser(param:any, userDetailsForHeader:any) {
    let headers1 = new HttpHeaders(userDetailsForHeader)
    headers1=headers1.append('stateless-authentication','true')
    return this.userHttpClient.post(environment.baseUrl + '/commons-iam-service/api/v1/obo/activate-user?'+ this.createParams(param), {}, { headers: headers1, responseType: 'text' });
  }

  updateUserPassword(headers: any, params: any) {
    let headers1 = new HttpHeaders(headers)
    headers1=headers1.append('stateless-authentication','true')
    return this.userHttpClient.put(environment.baseUrl +'/commons-iam-service/api/v1/obo/reset-password?'  + this.createParams(params), null, { headers: headers1, responseType: 'text' })
  }

  checkIfUserExists(userDetails:any) {
    const headers1 = new HttpHeaders().set('stateless-authentication','true');
    return this.userHttpClient.get(environment.baseUrl + '/commons-iam-service/api/v1/obo/register/check?' + this.createParams(userDetails), { headers:headers1,responseType: 'text' })
  }

  getModKey(headers: any) {
    const headers1 = new HttpHeaders()
      .set('X-USER', headers['X-USER'])
      .set('tenantName', headers['tenantName'])
      .set('stateless-authentication','true');

    const httpOptions = {
      headers: headers1,
      responseType: 'text' as 'text', // Ensure responseType is set correctly.
    };
    
    return this.userHttpClient.get(environment.baseUrl + '/commons-iam-service/api/v1/obo/reset-password' ,httpOptions)
  }

  resendOTP(param:any) {
    const headers1 = new HttpHeaders()
      .set('stateless-authentication','true');
    return this.userHttpClient.get(environment.baseUrl + '/commons-iam-service/api/v1/obo/activate-inactive-user?' + this.createParams(param),{headers:headers1})
  }

  getOTP(headers: any) {
    let headers1 = new HttpHeaders(headers)
    headers1=headers1.append('stateless-authentication','true')
    return this.userHttpClient.get(environment.baseUrl + '/commons-iam-service/api/v1/obo/resend-otp?', { headers: headers1, responseType: 'text' })
  }

  getUserDetailsLoginUser() {
    return this.userHttpClient.get(environment.tldURL + '/ctld/api/tenant/user/v1')
  }

  getCrossTenant(aID:number){
    const headers1 = new HttpHeaders().set('stateless-authentication','true')
    .set('Authorization',environment.Appkey);
    return this.userHttpClient.get(environment.baseUrl + '/commons-report-service/api/v3/datasets/name/ASSESSMENt_ORG-GetCreatedTeanantOfInstance/execute?page=1&size=1&params=INSTANCE_ID=' + aID,{headers:headers1})
  
  }

  getCrossTenantSession(tenant:string){
    const headers1 = new HttpHeaders().set('marketSession','true')
    return this.userHttpClient.post(environment.baseUrl + '/commons-iam-service/api/v1/obo/crosstenant/login?TENANT='+ tenant, null, { headers:headers1 , responseType:'text',observe: 'response' });
  }

  authenticateViaGoogle(token:any){
    let headers1 = new HttpHeaders().set('stateless-authentication','true')
    .set('Authorization',token)
    return this.userHttpClient.post(environment.baseUrl + '/commons-iam-service/api/v1/obo/social/login?platform=GOOGLE', null, { headers: headers1, responseType: 'text',observe: 'response' });
  }
}
