
<div class="p-2 ">
  <div mat-dialog-content  class="assessment-text-medium-15 assessment-text-grey-eight"> 
    <span *ngIf="data['status']=='PUBLISH'">{{'FORM.CREATED.SUCCESSFULLY'|translate}}{{data.assesmentInstId}}</span>
    <span *ngIf="data['status']=='DRAFT'">{{'FORM.SAVED.AS.DRAFT'|translate}}</span>
    <span *ngIf="data['status']=='Discard'">{{'NON.REVERSIBLE.ACTION'|translate}}</span>
  </div>
  <div mat-dialog-actions class="pt-4">
    <button class="btn-shade-2" mat-button (click)="onClose()">{{'COMMON.OKAY'|translate}}</button>
    <button class="btn-shade-3" *ngIf="data['status']=='Discard'" mat-button mat-dialog-close>{{'COMMON.CANCEL'|translate}}</button>
  </div>
</div>
