import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject } from 'rxjs';
//import * as appConfig from './../../assets/json/static-data/app-config.json';
import { environment } from 'src/environments/environment';
import { getLocalStorageItem } from '../shared/utility/utility';
import * as appConfig from './../shared/forms-controllers/app-Config'

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  constructor(private http: HttpClient,
    private translate: TranslateService) { }

  private configData = new BehaviorSubject<any>(null);
  fetchAppLangList() {
    return this.http.get('assets/json/config/app-language.json');
  }

  getTranslation(key: string): string {
    return this.translate.instant(key);
  }

  getConfigJSON(fileName: string) {
    return this.http.get('assets/' + fileName + '.json');
  }
  setAppConfig(configData:any) {
    this.configData=configData;
  }

  getConfigData(){
    return this.configData;
  }

  getConfigService(type:any) {
    let scopeValue = window.location.host.replace('www.','');
    if(scopeValue.startsWith("localhost:")){
      scopeValue = environment.defaultConfigScopeValue;
    }
    let params = this.createParams({
      appCode: environment.appCode,
      configType: type,
      languageCode: getLocalStorageItem('language'),
      scope: 'TENANT=' + scopeValue,
      version: environment.appConfigVersion
    })
    const headers = new HttpHeaders()
      .set('featured-authentication', 'true')
    return this.http.get(environment.baseUrl + appConfig.config.byType + params,{headers:headers,responseType: 'json', observe: 'response'});
  }

  createParams(params: any): HttpParams {
    let param = new HttpParams();
    for (const key of Object.keys(params)) {
      param = param.set(key, params[key]);
    }
    return param;
  }

}
