import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getLocalStorageItem, getLocalStorageItemEncrypt, getSessionStorageItem, getSessionStorageItemEncrypt } from '../utility/utility';

@Injectable()
export class GlobalInterceptorInterceptor implements HttpInterceptor {

  constructor(private router:Router) {}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    return throwError(() => err);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let sessionFlag:boolean=false;
    let isCertified:boolean=false
    console.log('IN')
    if(request.headers.get('stateless-authentication')){
      sessionFlag=true
      const tempHeaders = request.headers.delete("stateless-authentication");
      request = request.clone({ headers: tempHeaders });
    }

    if(request.headers.get('featured-authentication')){
      isCertified=true
      const tempHeaders = request.headers.delete("featured-authentication");
      request = request.clone({ headers: tempHeaders });
    }

    if((!this.router.url.startsWith('/login') || isCertified) && !sessionFlag && !request.url.endsWith('/webhook')){
      const accessStatus=getLocalStorageItem('access')
      if(request.headers.get("marketSession")){ 
        let marketId:any=getSessionStorageItemEncrypt('market-session',true)
        let marketHeader = request.headers.delete("marketSession");
        marketHeader=marketHeader.set('X-SESSIONID', marketId)
        request = request.clone({
          headers: marketHeader
        })
      }else if(this.router.url.startsWith('/quest-wizard') && !sessionFlag && getSessionStorageItem('isUserLoggedIn',true)=='true'){
        request = request.clone({
          setHeaders: {
            'X-SESSIONID':  JSON.parse(JSON.stringify(getSessionStorageItemEncrypt('cross-session-id',true)))
          }
        });
      }else{
        if(accessStatus=='private'){
          if(environment.defaultOnwer=='echo'){
            request = request.clone({ 
              setHeaders: {
                Authorization:this.getAuthorization(request)
  
              }
            });
          }else{
            request = request.clone({
              setHeaders: {
                'X-SESSIONID':  JSON.parse(JSON.stringify(getLocalStorageItemEncrypt('X-Sessionid')))  
              }
            });
          }
          
        }else{
          let url=request.url
          if(this.router.url.startsWith('/evaluation') || this.router.url.startsWith('/home/preview') || request.url?.includes('assessment-service') ){
            request = request.clone({
              setHeaders: {
                Authorization:  environment.privateAccessKey
              }
            });
          }else{
            request = request.clone({
              setHeaders: {
                Authorization:  environment.Appkey
              }
            });
          }
        }
      }
      
      
      return next.handle(request).pipe(catchError(x=> this.handleAuthError(x)));
    }
    return next.handle(request).pipe(catchError(x=> this.handleAuthError(x)));
  }

  getAuthorization(request:any){    
    if(request?.url?.includes('/upload/api/v1/image') || request?.url?.includes('/upload/api/v1/files')){
     return getLocalStorageItem('imageToken');
    }
    else {
      return getLocalStorageItem('token') && !getLocalStorageItem('token')?.includes('Bearer') ? 'Bearer '+getLocalStorageItem('token'):getLocalStorageItem('token')
    }
  }
}
