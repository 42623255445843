import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveyService } from 'src/app/services/takerServices/survey.service';
import { UserServiceService } from 'src/app/services/takerServices/user-service.service';
import { getSessionStorageItem, setLocalStorageItem, setLocalStorageItemEncrypt, setSessionStorageItem, setSessionStorageItemEncrypt } from 'src/app/shared/utility/utility';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { MainService } from 'src/app/services/main.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/services/app-config.service';
@Component({
  selector: 'app-connect-crafter',
  templateUrl: './connect-crafter.component.html',
  styleUrls: ['./connect-crafter.component.scss']
})
export class ConnectCrafterComponent implements OnInit {
  @Output() switch: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('google-button') gbutton: ElementRef = new ElementRef({});

  label: any;
  imageConfiguration: any;
  loginButtonClick: boolean = false;
  invalidLoginForm: boolean = false;
  showLoader: boolean = false;
  isSocialLoginInProcess: boolean = false;
  currentStep: number = 1;
  email: string = '';
  otp: string = '';
  isOverlayVisible: boolean = true;
  organizations: string[] = [];
  selectedOrganization: string = '';
  showButtons: boolean=false;
  showOrgList: boolean = false;
  modKey: string;
  imgConfiguration: any;
  appConfig: any;
  isLoading: boolean = false;

  constructor(
    private surveyService: SurveyService,
    private userService: UserServiceService,
    private snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private mainService: MainService,
    private translate:TranslateService,
    public appService: AppConfigService,

   ) { }

  ngOnInit(): void {
    this.appConfig=this.appService.getConfigData();
    this.imgConfiguration=this.mainService.getImage()
    console.log('inLog')
    
    this.label = this.surveyService.getData();

  }

  ngAfterViewInit(){
// @ts-ignore
google.accounts.id.initialize({
  client_id: this.appConfig['SURVEY_APP_CONFIG.GOOGLE_CLIENT_KEY'],
  callback: this.handleCredentialResponse.bind(this),
  auto_select: false,
  cancel_on_tap_outside: true,

});
// @ts-ignore
google.accounts.id.renderButton(
  // @ts-ignore
  document.getElementById('google-button'),
  { theme: "filled_blue", size: "large", width: "100%" }
);
// @ts-ignore
google.accounts.id.prompt((notification: PromptMomentNotification) => { });

  }

  getILText(key:string){
    return this.translate.instant(key)
  }

  onOrganizationSelected(organization: string) {
    this.selectedOrganization = organization;
  }

  verifyOtp() {
    this.isLoading = true
    const enteredOtp = this.otp;
    const verifyOtpData = {
      modKey: this.modKey,
      otp: enteredOtp,
      tenantLogin: this.appConfig['SURVEY_APP_CONFIG.DEFAULT_TENANT'],
      userLogin: this.email
    };
  
    this.authService.takerverifyOtp(verifyOtpData).subscribe({
      next: (response) => {
        setSessionStorageItemEncrypt('market-session', response.sessionId, true);
        this.processCrossTenant();
      },
      error: (error) => {
        this.isLoading = false
        console.error('API error:', error);
        this.snackBar.open(this.getILText('ERR_OTP_VERIFY'), 'Close', {
          duration: 3000,
          panelClass: ['blue-snackbar']
        });
        throw error;
      }
    });
  }


isValidEmail(): boolean {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(this.email);
  }

  setCurrentStep(step: number): void {
    this.currentStep = step;
  }

  goBack(): void {
    if (this.currentStep > 1) {
      this.setCurrentStep(this.currentStep - 1);
    }
  }

  submitEmail() {
    const email = this.email;

    if (!email) {
      this.snackBar.open(this.getILText('VALIDATION_MSG_EMAIL'), 'Close', {
        duration: 3000,
        panelClass: ['blue-snackbar']
      });
      return;
    }

    let data={
      context:this.appConfig['SURVEY_APP_CONFIG.DEFAULT_TENANT'],
      email:email
    }

    this.authService.selfRegistration(data).subscribe(
      (response) => {
        console.log('API response:', response);
        this.modKey = response.modKey;
        this.snackBar.open(this.getILText('OTP_SENT_SUCCESS'), 'Close', {
          duration: 3000,
          panelClass: ['blue-snackbar']
        });
        this.currentStep=3
      },
      (error) => {
        console.error('API error:', error);
        this.snackBar.open(this.getILText('ERR_SELF_REGISTER'), 'Close', {
          duration: 3000,
          panelClass: ['error-snackbar']
        });
        throw error;
      }
    );

  }

  

  async handleCredentialResponse(response: any) {
    // Here will be your response from Google.
    // console.log(response);
    this.isSocialLoginInProcess=true
    this.userService.authenticateViaGoogle(response.credential).subscribe({
      next: (socialResp: any) => {
        console.log(socialResp.headers.get('X-Sessionid'))
        let sessionViaSocial:any=socialResp.headers.get('X-Sessionid')
        setSessionStorageItemEncrypt('market-session', sessionViaSocial,true);
        this.processCrossTenant()
      }, error: (socialREspErr: any) => {
        this.isSocialLoginInProcess=false
        console.log(socialREspErr);
        this.snackBar.open(this.getILText('ERR_TRY_LATER'), 'OK', {
          duration: 3000
        });
        throw socialREspErr
      }
    })
  }

  onSubmit(loginFormData: NgForm) {

    this.loginButtonClick = true;
    if (loginFormData.invalid) {
      this.invalidLoginForm = true;
      return;
    }
    let _isTenantAdminLogin = loginFormData.value.email.includes('@@')

    this.showLoader = true;

    let userDetails;
    if (_isTenantAdminLogin) {
      let loginId = loginFormData.value.email.split('@@');
      userDetails = {
        password: loginFormData.value.password,
        tenantLogin: loginId[1],
        userLogin: loginId[0]
      }
    } else {
      userDetails = {
        password: loginFormData.value.password,
        tenantLogin: this.appConfig['SURVEY_APP_CONFIG.DEFAULT_TENANT'],
        userLogin: loginFormData.value.email
      }
    }

    this.userService.authenticateUser(userDetails).subscribe({
      next: (userLoggedInResponseData: any) => {
        setSessionStorageItemEncrypt('market-session', userLoggedInResponseData.body.sessionId,true);
        this.processCrossTenant()
      },
      error: (error: any) => {
        this.showLoader = false;
        console.error('Unable to login with given details', error);
        this.snackBar.open(this.getILText('ERR_TRY_LATER'), 'OK', {
          duration: 3000
        });
        throw error;
      }
    })
  }


  processCrossTenant() {
    const aID = Number(getSessionStorageItem('surveyIID',true));
    this.userService.getCrossTenant(aID).subscribe({
      next: (resp: any) => {
        let crossTenant = resp[0].login
        setSessionStorageItem('tenant-session-active', crossTenant,true)

        this.userService.getCrossTenantSession(crossTenant).subscribe({
          next: (tenantSession: any) => {
            console.log(tenantSession.headers.get('X-Sessionid'));
            let sessionIdAPP: any = tenantSession.headers.get('X-Sessionid')
            setSessionStorageItemEncrypt('cross-session-id', sessionIdAPP,true);
            setSessionStorageItem('isUserLoggedIn','true',true)
            this.userService.getUserDetailsLoginUser().subscribe({
              next: (userDetails: any) => {
                
                setSessionStorageItem('assessedEID', userDetails.id,true)
                setSessionStorageItem('assesseAID', userDetails.id,true)
                setSessionStorageItem('user_name', userDetails.firstName.charAt(0).toUpperCase() +
                  userDetails.firstName.slice(1),true);
                setSessionStorageItem('user_id', userDetails.id,true);
                setSessionStorageItem('login_id', userDetails.login,true);
                setSessionStorageItem('first_name', userDetails.firstName,true);
                setSessionStorageItem('last_name', userDetails.lastName,true);
                window.location.reload()
              },
              error: (error: any) => {
                this.isSocialLoginInProcess=false
                this.showLoader = false;
                this.snackBar.open(this.getILText('ERR_FAILED_DATA_RETRIEVE'), 'Close', {
                  duration: 3000
                });
                throw error;
              }
            })
          }, error: (err: any) => {
            this.isSocialLoginInProcess=false
            this.snackBar.open(this.getILText('ERR_TRY_LATER'), 'Close', {
              duration: 3000,
            });
            throw err;
          }
        });

      }, error: (crossTenantErr: any) => {
        console.log(crossTenantErr)
        this.isSocialLoginInProcess=false
        this.snackBar.open(this.getILText('ERR_TRY_LATER'), 'Close', {
          duration: 3000
        });
        throw crossTenantErr;
      }
    });
  }

  change(event: any) {
    this.switch.emit('forgot-password');
  }
}