import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-msg-roller',
  templateUrl: './msg-roller.component.html',
  styleUrls: ['./msg-roller.component.scss']
})
export class MsgRollerComponent implements OnInit {
  imgConfiguration: any;
  
  constructor( public dialogRef: MatDialogRef<MsgRollerComponent>,
    private route: Router,
    private mainService: MainService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.imgConfiguration=this.mainService.getImage()

    console.log(this.data)
  }

}
