import { Pipe, PipeTransform } from '@angular/core';
import { getLocalStorageItem, getSessionStorageItem } from '../utility/utility';

@Pipe({
  name: 'mlTranslate'
})
export class MlTranslatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value.length) {
      if (value.length == 1) {
        return value[0].text;
      } else {        
        let res = value.find((item: any) => item.language.code == getSessionStorageItem('language',true));
        if (res == undefined) {
          return value[0].text;
        }
        else return res.text;
      }
    }
  }

}
