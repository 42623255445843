
export const environment = {
  production: false,
  baseUrl: 'https://develb.assessment.metaecho.com',
  // baseUrl: 'https://dev.platformcommons.org/gateway',
  // tldURL: 'https://dev.platformcommons.org',
  tldURL:'https://develb.assessment.metaecho.com',
  appContext: 'commons_assessment-org',
  respondentContext:"commons_assessment-taker",
  Appkey: 'Appkey YXBwS2V5OjNiNWQwNjdjLWEyYWYtNGNkNS1hZGY2LTI2MDE3MmQzYzVlNCxhcHBDb2RlOkNPTU1PTlNfQVBQLkFTU0VTU01FTlRfT1JH',
  privateAccessKey:'Apikey 118af537-a527-42d8-8492-abb51801a26e',
  XAPPID: '105',
  XAPPKEY: '105265454',
  encryptKey: 'd46749093824a50fd75883031800ef4e632509b0beb01ce1d6ebc283cdb85067ad5450d91aa1c1e200d9f972c3b83a84d410a6d802e6c5605d63ffcad39c6212',
  defaultConfigScopeValue: "dev.platformcommons.org",
  // defaultConfigScopeValue: "echo.commons.social",
  appCode: 'COMMONS_APP.ASSESSMENT_ORG',
  appConfigVersion: '1.0.0',
  defaultOnwer:'echo'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
