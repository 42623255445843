import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginAuthGuard } from './guards/login-auth.guard';
import { PrivateAccessGuard } from './guards/private-access.guard';
import { QuizUserAuthGuard } from './guards/quiz-user-auth.guard';

const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/market-features/market-features.module').then(m => m.MarketFeaturesModule), 
  },
  {
    path: 'workplace',
    loadChildren: () => import('./modules/workplace/workplace.module').then(m => m.WorkplaceModule), canActivate:[PrivateAccessGuard]
  },
  {
    path: 'evaluation',
    loadChildren: () => import('./modules/assessment/assessment.module').then(m => m.AssessmentModule),canActivate:[PrivateAccessGuard]
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./modules/user-profile/user-profile.module').then(m => m.UserProfileModule),canActivate:[PrivateAccessGuard]
  },
  {
    path: 'quest-wizard',
    loadChildren: () => import('./modules/quest-quantum/quest-quantum.module').then(m => m.QuestQuantumModule),canActivate:[QuizUserAuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule), canActivate:[LoginAuthGuard]
  },
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
