import { Component, TemplateRef, ViewChild, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-new-form-popup',
  templateUrl: './new-form-popup.component.html',
  styleUrls: ['./new-form-popup.component.scss']
})
export class NewFormPopupComponent implements OnInit {
  domainNames: string[] = [];
  domain: string

  @ViewChild('ListDomainTemplate', { static: true }) myTemplate: TemplateRef<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public dialogRef: MatDialogRef<NewFormPopupComponent>) { }
ngOnInit(){

  console.log(this.data)
  if (this.data && this.data.domain) {
    this.domainNames = this.data.domain.map((domain: { name: any }) => domain.name);
    console.log(this.domainNames);
  }
}

selectDomainForNewForm(selectedDomain: any) {
    this.dialogRef.close({ event: 'del', data: selectedDomain });
  }
  
  closeAll(){
    this.dialogRef.close();
  }
}
