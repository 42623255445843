<div class="container p-5 " style="width: 100%; height:100%">
    <div style="height:100% ;" class="d-flex flex-column" *ngIf="!imgPresent">
        <section >
            <span class="d-flex">
                <input class="input-box" id="imgUrl" type="url" placeholder="Paste image address">
                <button class="ml-5" (click)="generateUrl()">{{'COMMON.SUBMIT'|translate}}</button>
            </span>
            <section class="d-flex flex-column align-items-center mt-5 mb-3">
                <h2 class="text">{{'OR'|translate|uppercase}}</h2>
                <div class="divider"></div>
            </section>
        </section>
        <section class="upload-img-container p-4 d-flex justify-content-center align-items-center">
            <div *ngIf="loader" class="spinner-border text-primary" role="status">
                <span class="sr-only"></span>
            </div>
            <div *ngIf="!loader" (click)="fileUpload.click()" class="input-img d-flex align-items-center justify-content-center">
                <img class="p-2" [src]="imgConfiguration['SURVEY_APP.UPLOAD_FILE']"><span class="pl-5 text">Upload a file</span>
            </div>
            <input #fileUpload [hidden]=true (change)="onImageUpload($event)" type="file" placeholder="Upload Image"
                title="Upload Image">
        </section>
    </div>
    <div *ngIf="imgPresent" style="width: 100%; height:100%">
        <img style="width: 100%;height: 100%;" [src]="data.imgUploaded">
        
        <img (click)="deleteImg()" class="action-btn del-icon" [src]="imgConfiguration['SURVEY_APP.DELETE_RED']">
        <img (click)="editImage()" class="action-btn edit-icon" [src]="imgConfiguration['SURVEY_APP.EDIT_ICON']">
    </div>
</div>
