import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { getSessionStorageItem, removeSessionStorageItem, setLocalStorageItem, setLocalStorageItemEncrypt } from 'src/app/shared/utility/utility';
import { MainService } from 'src/app/services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { concatMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/services/app-config.service';

export interface Organization {
  login: string;
  name: string;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  inProgress: boolean;
  organizations: Organization[] = [];
  showOrgFields: boolean = false;
  showPasswordFields: boolean = false;
  passwordVisible: boolean = false;
  isElementVisible: boolean = true;
  currentStep: number = 1;
  isDialogMode: boolean = false;
  selectedOrganization: string;
  private userId: number;
  private modKey: string;
  otp: string;
  private orgId: string;
  enteredOtp: any;
  redirectURL: any;
  newPassword: string;
  key: string;
  showPasswordInput: boolean = false;
  imgConfiguration: any;
  appConfig: any;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    private route: Router,
    private service: MainService,
    private mainService: MainService,
    private dialogRef: MatDialogRef<LoginComponent>,
    private translate:TranslateService,
    public appService: AppConfigService,

    @Inject(MAT_DIALOG_DATA) public accessWay: any
  ) {
  }

  ngOnInit(): void {
    this.appConfig=this.appService.getConfigData();
    this.imgConfiguration=this.mainService.getImage()
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      orgId: [''],
      enteredOtp: ['', Validators.required],
      newPassword: [''],
      otp: ['']
    });
    this.redirectURL = getSessionStorageItem('primary-url');
    removeSessionStorageItem('primary-url');

    this.loginForm.get('email')?.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(email => this.handleEmailChange(email))
    ).subscribe();
  }

  getILText(key:string){
    return this.translate.instant(key)
  }

  validateNumber(event: any) {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  }

  onCreateAccountClick(): void {
    this.route.navigate(['/login/select-organisation']);
  
    if (this.isDialogMode) {
      this.dialogRef.close();
    }
  }

  toggleElementVisibility() {
    this.isElementVisible = !this.isElementVisible;
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  getSession() {
    this.inProgress = true;
    const email = this.loginForm.get('email')?.value;

    if (!email) {
      this.snackBar.open(this.getILText('VALIDATION_MSG_EMAIL'), 'Close', {
        duration: 3000,
        panelClass: ['blue-snackbar']
      });
      this.inProgress = false;
      return;
    }

    if (email.includes('@@')) {
      this.showPasswordFields = true;
      this.inProgress = false;
    }

    else {
      this.snackBar.open(this.getILText('INVALID_EMAIL'), 'Close', {
        duration: 3000,
        panelClass: ['blue-snackbar']
      });

      this.showOrgFields = false;
      this.showPasswordFields = false;
      this.inProgress = false;
      return;
    }
  }

  getNumberofAtTheRates(str: string) {
    if (str.includes('@')) {
      return str.split("@").length - 1
    } else {
      return '';
    }
  }
  private handleEmailChange(email: string) {
    const numberOfAtTheRate = this.getNumberofAtTheRates(email);

    if (email.includes('@') && numberOfAtTheRate === 1) {
      return this.authService.fetchUserDataByEmail(email).pipe(
        switchMap(response => {
          if (response && response.length > 0) {
            this.organizations = response;
            this.showOrgFields = this.organizations.length > 0;
            this.showPasswordFields = true;
          } else {
            this.organizations = [];
            this.showOrgFields = false;
            this.showPasswordFields = false;
          }
          return [];
        })
      );
    } else {
      return [];
    }
  }

  login() {
    const email = this.loginForm.value.email;
    const orgId = this.loginForm.value.orgId;
    const password = this.loginForm.value.password;

    const numberOfAtTheRate = this.getNumberofAtTheRates(email);

    if (!email.includes('@')) {
      this.snackBar.open(this.getILText('ENTER_VALID.EMAIL'), 'Close', {
        duration: 3000,
        panelClass: ['blue-snackbar']
      });
      return;
    }

    if (email.includes('@') && numberOfAtTheRate === 1) {
      // The email contains only one '@', so fetch organizations from API

      if (this.organizations?.length === 0) {
        this.authService.fetchUserDataByEmail(email).subscribe({
          next: (response) => {
            if (response && response?.length > 0) {
              this.organizations = response;
              this.showOrgFields = this.organizations.length > 0;
              this.showPasswordFields = true;
              this.inProgress = false;
            }
            else {
              this.snackBar.open(this.getILText('EMAIL_NOT_EXIST'), 'Close', {
                duration: 4000,
                panelClass: ['blue-snackbar']
              });
              this.inProgress = false;

            }
          },
          error: (error) => {
            console.error('Error fetching organizations:', error);
            this.showOrgFields = false;
            this.showPasswordFields = false;
            this.inProgress = false;
            this.snackBar.open(this.getILText('ERR_FETCHING_ORGS'), 'Close', {
              duration: 3000,
              panelClass: ['blue-snackbar']
            });
            throw error;
          }
        });
      }
    }

    if (email.includes('@@')) {
      this.showOrgFields = false;
      this.showPasswordFields = true;
    }

    let tenantLogin: any, userLogin = '';

    let finalEmailSplitValue = (email && numberOfAtTheRate === 2) ? email.split('@@') : false;

    userLogin = finalEmailSplitValue === false ? '' : finalEmailSplitValue[0];
    tenantLogin = finalEmailSplitValue === false ? '' : finalEmailSplitValue[1];
    if ((email && password && orgId) || (userLogin && tenantLogin && password)) {
      this.inProgress = true;
      this.selectedOrganization = orgId;
      const authObservable = userLogin && tenantLogin && password
        ? this.authService.authenticateViaSession({ password, tenantLogin, userLogin })
        : this.authService.checkUserActiveStatus(email, orgId).pipe(
          concatMap((response: any) => {
            if (response === 'ACTIVE_USER') {
              return this.authService.authenticateViaSession({
                password,
                tenantLogin: numberOfAtTheRate === 2 ? tenantLogin : orgId,
                userLogin: numberOfAtTheRate === 2 ? userLogin : email,
              });
            } else if (response === 'INACTIVE_USER') {
              return this.authService.activateUser(email, orgId,this.appConfig['SURVEY_APP_CONFIG.DEFAULT_TENANT']).pipe(
                concatMap((activationResponse: any) => {
                  this.inProgress = false;
                  this.currentStep = 2;
                  this.userId = activationResponse.id;
                  this.modKey = activationResponse?.responseDTO?.modKey;
                  this.snackBar.open(this.getILText('OTP_RESENT_SUCCESS'), 'Dismiss', {
                    duration: 3000,
                  });
                  return of(null);
                }),
                catchError((activationError) => {
                  this.inProgress = false;
                  this.snackBar.open(this.getILText('ERR_ACTIVATING_USER'), 'Dismiss', {
                    duration: 3000,
                  });
                  throw activationError;
                })
              );
            } else {
              this.inProgress = false;
              return throwError('Unexpected response');
            }
          })
        );

      authObservable.subscribe({
        next: (response) => {
          if (response) {
            const sessionId = response.headers.get('X-Sessionid');
            setLocalStorageItem('access', 'private');
            setLocalStorageItemEncrypt('X-Sessionid', sessionId);

            this.authService.setLoggedInStatus(true);
            this.inProgress = false;

            if (this.accessWay['isDrawerMode'] && this.accessWay['isDrawerMode'] == true) {
              window.location.reload();
            } else {
              if (this.redirectURL) {
                window.location.href = this.redirectURL;
              } else {
                this.route.navigate(['/home/welcome']).then(() => {
                  this.service.fetchUserProfile();
                });
              }
            }
          }
        },
        error: (error) => {
          this.snackBar.open(this.getILText('AUTHENTICATION_ERR'), 'Dismiss', {
            duration: 3000,
          });
          this.inProgress = false;
          console.error('Error during authentication:', error);
          throw error;
        },
      });
    }

  }

  activateAccount() {
    const enteredOtp = this.loginForm.value.enteredOtp;
    this.inProgress = true;
  
    this.authService.verifyOtpIndividual(this.userId, this.modKey, enteredOtp, this.selectedOrganization).subscribe({
      next: (response: any) => {
        if (response.includes('User has been activated successfully')) {
          this.currentStep = 1;
          this.inProgress = false;
          this.snackBar.open(this.getILText('AUTH.TEXT.FORGOT_PASSWORD.ACTIVATED'), 'Close', {
            duration: 3000,
            panelClass: ['blue-snackbar']
          });
        }
      },
      error: (error: any) => {
        this.inProgress = false;
        console.error('Error during OTP verification:', error);
        if (error.error?.errorMessage.includes('is already activated')) {
          this.snackBar.open(this.getILText('ACTIVE_ACCOUNT'), 'Dismiss', {
            duration: 3000,
          });
        } else {
          this.snackBar.open(this.getILText('OTP_VERIFICATION_ERR'), 'Dismiss', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
          throw error;
        }
      }
    });
  }

  resetPassword() {
    this.inProgress=true;
    const enteredEmail = this.loginForm.value.email;
    const selectedOrgId = this.loginForm.value.orgId;
    const enteredOtp = this.loginForm.value.otp;
    const newPassword = this.loginForm.value.newPassword;

    this.authService.submitPasswordReset(this.key, enteredOtp, newPassword, enteredEmail, selectedOrgId).subscribe({
      next: (passwordResetResponse: any) => {
        console.log('Password Reset API response:', passwordResetResponse);
        this.inProgress=false;

        if (passwordResetResponse) {
          this.snackBar.open(this.getILText('AUTH.TEXT.CHANGED_PASSWORD.SUCCESSFUL'), 'Close', {
            duration: 3000,
            panelClass: ['blue-snackbar']
          });

          this.loginForm.reset();
          this.currentStep = 1;
        }
      },
      error: (passwordResetError: any) => {
        this.inProgress=false
        this.snackBar.open(this.getILText('RESEND_PASS_ERR'), 'Dismiss', {
          duration: 3000,
        });
        console.error('Error calling Password Reset API:', passwordResetError);
        throw passwordResetError;
      }
    });
  }

  checkActiveStatus() {
    this.inProgress = true;
    const enteredEmail = this.loginForm.value.email;
    const selectedOrgId = this.loginForm.value.orgId;

    this.authService.checkUserActiveStatus(enteredEmail, selectedOrgId).subscribe({
      next: (statusResponse: any) => {
        if (statusResponse === 'ACTIVE_USER') {
          this.authService.resetPassword(enteredEmail, selectedOrgId).subscribe({
            next: (resetPasswordResponse: any) => {
              this.key = resetPasswordResponse;
              this.authService.resendOtp(enteredEmail, selectedOrgId).subscribe({
                next: (resendOtpResponse: any) => {
                  console.log('Resend OTP API response:', resendOtpResponse);
                  this.showPasswordInput = true;
                  this.inProgress = false;
                },
                error: (resendOtpError: any) => {
                  this.inProgress = false;
                  console.error('Error calling Resend OTP API:', resendOtpError);
                  this.snackBar.open(this.getILText('ACTIVE_STATUS_ERR'), 'Dismiss', {
                    duration: 3000
                  });
                  throw resendOtpError;
                }
              });
            },
            error: (resetPasswordError: any) => {
              console.error('Error calling Reset Password API:', resetPasswordError);
              this.snackBar.open(this.getILText('ACTIVE_STATUS_ERR'), 'Dismiss', {
                duration: 3000
              });
              this.inProgress = false; 
              throw resetPasswordError;
            }
          });
        }
      },
      error: (error: any) => {
        console.error('Error checking user active status:', error);
        this.snackBar.open(this.getILText('ACTIVE_STATUS_ERR'), 'Dismiss', {
          duration: 3000,
        });
        this.inProgress = false;
        throw error;
      }
    });
  }

  forgotPassword() {
    this.currentStep = 3;
    this.showOrgFields = true;
  }
  goBack() {
    this.currentStep = 1
  }
} 
