<div class="col-12 px-md-5 py-md-2 app-nav-bar justify-content-start justify-content-md-between d-flex flex-row">

    <div class="d-flex justify-content-between w-100 align-items-center margin-auto mx-2 d-md-none">
        <div class="d-flex">
            <button class="navbar-toggler d-block d-md-none col-1 me-3 toggle-btn " type="button"
                data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <img [src]="imgConfiguration['SURVEY_APP.HAMBURGER_MENU_GREY']" alt="Hamburger Icon">
            </button>

            <a [routerLink]="['/home/welcome']" class="d-block d-md-none temp-logo cursor-pointer">
                <img class="h-100 w-100" [src]="imgConfiguration['SURVEY_APP.ZENFORMS_APP_LOGO']">
            </a>
        </div>


        <button *ngIf="!loggedIn"
            class="assessment-text-medium-14 login-button trigger py-2 px-4 d-md-none custom-login-btn"
            [routerLink]="['/login']">{{'LOGIN'| translate}}</button>
    </div>

    <div #navbarCollapse class="collapse navbar-collapse py-0 p-2 pe-4 mt-0 pt-0 mt-3" id="navbarSupportedContent">

        <ul class="navbar-nav d-flex align-items-left ms-4 pt-2">
            <ng-container *ngIf="loggedIn">

                <li class="nav-item align-self-left align-self-lg-center py-2 d-flex ">
                    <div class="profile-icon p-1 col-3"
                        (click)="goToUserProfile(); navbarCollapse.classList.toggle('show')">
                        <img [src]="profileImg" class="w-100 h-100 border-radius-circle">
                    </div>
                    <div class="d-flex flex-column ps-2 col-9 justify-content-center">
                        <span class="assessment-text-medium-16 text-minimize">{{userProfile['firstName']}}
                            {{userProfile['lastName']}}</span>
                        <span class="assessment-text-medium-10 company-name assessment-text-grey-eight">{{ companyName|uppercase }}</span>

                    </div>
                </li>
                <hr class="left-nav">
            </ng-container>
            <li class="nav-item align-self-left align-self-lg-center py-2 assessment-text-medium-14 assessment-text-grey-ten"
                (click)="navigatePath('home/sector'); navbarCollapse.classList.toggle('show')">
                {{ 'DOMAINS' | translate }}
            </li>
            <li *ngIf="loggedIn"
                class="nav-item align-self-left align-self-lg-center py-2 assessment-text-medium-14 assessment-text-grey-ten"
                (click)="navbarCollapse.classList.toggle('show')">
                {{'LABEL_NOTIFICATION'| translate}}
            </li>
            <hr class="left-nav">


            <li *ngIf="loggedIn"
                class="nav-item align-self-left align-self-lg-center py-2 assessment-text-medium-14 assessment-text-grey-ten"
                (click)="navigatePath('workplace');navbarCollapse.classList.toggle('show')">
                {{'LABEL_WORKSPACE'| translate}}
            </li>


            <li class="nav-item align-self-left align-self-lg-center py-2 assessment-text-medium-14 assessment-text-grey-ten"
                *ngIf="isAdmin" [routerLink]="['/workplace/user-list']"
                (click)="navbarCollapse.classList.toggle('show')">
                {{'LABEL_ADMIN_USER_LIST'| translate}}
            </li>

            <!-- <li *ngIf="loggedIn"
                class="nav-item align-self-left align-self-lg-center py-2 assessment-text-medium-14 assessment-text-grey-ten"
                (click)="navbarCollapse.classList.toggle('show')">
                Account settings
            </li> -->
            <li class="nav-item align-self-left align-self-lg-center py-2 assessment-text-medium-14 assessment-text-grey-ten"
                (click)="selectLanguageTemplate(LanguageSelectTemplate)">
                {{'LANGUAGE'|translate}}
            </li>
            <!-- <li class="nav-item align-self-left align-self-lg-center py-2 assessment-text-medium-14 assessment-text-grey-ten"
                (click)="navbarCollapse.classList.toggle('show')">
                Help
            </li> -->

            <hr class="left-nav">

            <!-- <li *ngIf="loggedIn"
                class="nav-item align-self-left align-self-lg-center py-2 assessment-text-medium-14 assessment-text-grey-ten"
                disabled (click)="goToEditProfile();navbarCollapse.classList.toggle('show')">
                {{'EDIT.PROFILE'|translate}}
            </li> -->

            <ng-container *ngIf="loggedIn">
                <li class="nav-item align-self-left align-self-lg-center py-2 assessment-text-medium-14 assessment-text-grey-ten"
                    (click)="logOut();navbarCollapse.classList.toggle('show')">
                    {{'LOGOUT' | translate}}
                </li>
            </ng-container>

            <ng-container *ngIf="!loggedIn">
                <li class="nav-item align-self-left align-self-lg-center py-2 assessment-text-medium-14 assessment-text-grey-ten"
                    [routerLink]="['/login']" (click)="navbarCollapse.classList.toggle('show')">
                    {{'LOGIN'| translate}}
                </li>
            </ng-container>


        </ul>
        <button class="close-button cursor-pointer position-fixed">
            <img [src]="imgConfiguration['SURVEY_APP.CLOSE_ICON']" alt=""
                (click)="navbarCollapse.classList.toggle('show')">
        </button>

    </div>




    <div class="d-none d-md-block col-2 custom-logo nav-hyperlink cursor-pointer">
        <a [routerLink]="['/home/welcome']">
            <img [src]="imgConfiguration['SURVEY_APP.ZENFORMS_APP_LOGO']" class="w-100 h-100 ">
        </a>
    </div>

    <!-- <div class="d-flex align-items-center input-div col-4 py-2 px-3">
        <input placeholder="Search for domains, assessments"
            class="assessment-text-regular-14 w-100 d-none d-md-block border-none p-0 m-0">
        <img src="assets/images/domain/search.svg" class="d-none d-md-block">
    </div> -->
    <div class="col-6  d-none d-md-flex justify-content-end align-items-center">
        <div class="d-flex align-items-center float-right nav-hyperlink ">

            <ng-container *ngIf="!loggedIn">
                <a class="assessment-text-medium-16 assessment-text-grey-ten m-0 cursor-pointer"
                    [routerLink]="['/home/sector']">
                    {{ 'DOMAINS' | translate }}
                </a>



                <button class=" assessment-text-medium-14 ms-4 login-button dropdown trigger py-2 px-4"
                    (click)="openLoginDialog()">{{'LOGIN'| translate}}</button>

                <a [routerLink]="['/login/select-organisation']">
                    <button class="px-4 ms-2 py-2 assessment-text-medium-14 assessment-text-white signup-button">
                        {{ 'SIGNUP' | translate }}
                    </button>
                </a>


                <!-- <div *ngIf="newLangObjList" class="user-language ms-2">
                    <button class="p-2 web-button trigger dropbtn"><img [src]="imgConfiguration['SURVEY_APP.WEB_ICON']"
                            class="w-100" alt=""></button>
                    <div class="pop-up-style custom-me" aria-labelledby="navbarDropdown">
                        <div class="custom-style-pop-up p-3">
                            <div class=" d-flex align-items-center flex-row ">
                                <img style="width: 12px;" class="me-1" [src]="imgConfiguration['SURVEY_APP.WEB_ICON']">
                                <span class="assessment-text-medium-12 assessment-text-grey-eight">{{'HINT_TEXT_LANGUAGE'| translate}}</span>
                            </div>
                            <div class="mt-3">
                                <div class="mt-1" *ngFor="let lang of newLangObjList"
                                    (click)="configureAppLanguage(lang)">
                                    <span class="assessment-text-medium-14 cursor-pointer "
                                        [ngClass]="appLanguage==lang.value?'assessment-text-primary-one':'assessment-text-grey-eight'">{{lang.value}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </ng-container>

            <ng-container *ngIf="loggedIn">

                <a class="assessment-text-medium-16 cursor-pointer assessment-text-grey-ten"
                    [class.active-link]="activePage === 'home'" [routerLink]="['/home/sector']">
                    {{ 'DOMAINS' | translate }}
                </a>

                <a class="assessment-text-medium-16 ms-4 cursor-pointer assessment-text-grey-ten"
                    [class.active-link]="activePage === 'workplace'" [routerLink]="['/workplace']">
                    {{'LABEL_WORKSPACE'| translate}}
                </a>

                <div class="px-4 ">
                    <img [src]="imgConfiguration['SURVEY_APP.NOTIFICATION_BELL']">
                </div>

                <div class="user-image p-2 cursor-pointer">
                    <img [src]="profileImg" class="border-radius-circle h-100 w-100 ">
                    <div class="pop-up-style p-2 custom-me " aria-labelledby="navbarDropdown">
                        <div class="custom-style-pop-up py-3">
                            <div class="col-12 d-flex align-items-center">
                                <div class="profile-icon p-1 col-3 ps-2">
                                    <img [src]="profileImg" class="w-100 h-100 border-radius-circle">
                                </div>
                                <div class="d-flex flex-column ps-2 col-9">
                                    <span class="assessment-text-medium-16 text-minimize">{{userProfile['firstName']}}
                                        {{userProfile['lastName']}}</span>

                                    <span class="assessment-text-medium-10 assessment-text-grey-eight company-name">{{
                                        companyName|uppercase }}</span>


                                </div>
                            </div>
                            <hr class="dropdown-hr">
                            <div class="px-2">
                                <button (click)="navigatePath('workplace')"
                                    class="assessment-text-medium-14 cursor-pointer header-button w-100 ">{{'LABEL_WORKSPACE'| translate}}</button>

                                <button class="assessment-text-medium-14 cursor-pointer header-button w-100 pt-2"
                                    *ngIf="isAdmin" (click)="navigateToUserList()">{{'LABEL_ADMIN_USER_LIST' | translate}}</button>


                                <!-- <button class="assessment-text-medium-14  header-button w-100 pt-2">Account
                                                                settings</button> -->
                                <button (click)="selectLanguageTemplate(LanguageSelectTemplate)"
                                    class="assessment-text-medium-14 cursor-pointer header-button w-100 pt-2 d-flex justify-content-between ">
                                    <span>{{'LANGUAGE'|translate}}</span>
                                    <span class="align-items-center d-flex">{{ appLanguage }} <img
                                            [src]="imgConfiguration['SURVEY_APP.WEB_ICON']" alt="" class="ms-2"> </span>
                                </button>


                                <!-- <button class="assessment-text-medium-14  header-button w-100 pt-2">Help</button> -->
                            </div>
                            
                            <hr class="dropdown-hr">
                            <div class="ps-2">
                                <button class="assessment-text-medium-14 cursor-pointer header-button w-100 "
                                disabled (click)="goToEditProfile()">{{'EDIT.PROFILE'|translate}}</button>
                                <button class="assessment-text-medium-14 cursor-pointer header-button w-100 pt-2"
                                    (click)="logOut()"> {{'LOGOUT' | translate}}</button>

                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>



</div>

<ng-template #LanguageSelectTemplate>
    <div class="h-100 d-flex flex-column">
        <p>{{'SELECT_LANGUAGE_HINT_TEXT' | translate}}</p>
        <div class="d-flex" mat-dialog-actions>
            <div class="col-lg-4 mt-4  pe-2" *ngFor="let lang of newLangObjList" mat-dialog-close>
                <button class="btn-outline-solid assessment-text-medium-16 lang-select w-100 text-start"
                    (click)="configureAppLanguage(lang)" [class.selected]="isLangSelected(lang)">
                    {{ lang.value |translate|titlecase }}
                </button>
            </div>
        </div>
    </div>
</ng-template>