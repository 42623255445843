<div class="d-flex align-items-start pb-2 mb-3 flex-wrap">
    <span class="radio-icon mt-0" [ngStyle]="{'background-image':'url('+imgConfiguration['SURVEY_APP.RADIO_ICON']+')'}"></span>
    <span class="assessment-text-grey-nine assessment-text-regular-13">{{ getText(question?.questionText) }}</span>
  
    <div *ngIf="question?.defaultOptionsList?.length > 0" class="ps-4 w-100">
      <div class="mt-3 ms-2" *ngFor="let option of question?.defaultOptionsList">
        <div class="question-container py-3 px-3 d-flex justify-content-between my-3 align-items-center">
          <p class="assessment-text-grey-nine assessment-text-regular-13 mb-0">{{ getText(option?.options?.optionName) }}</p>
          <span class="btn-shade-5 p-1 text-uppercase">{{ displayQuestionType(option?.options?.questionType?.code, false, true) }}</span>
        </div>
  
        <!-- Recursive part: If there are child questions, display them using the same component -->
        <div *ngIf="option?.childQuestionsList?.length > 0" class="ps-4 w-100">
          <app-child-question-preview *ngFor="let childQuestion of option?.childQuestionsList" [question]="childQuestion" [imgConfiguration]="imgConfiguration"></app-child-question-preview>
        </div>
      </div>
    </div>
  </div>