import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-form-mode-selector',
  templateUrl: './form-mode-selector.component.html',
  styleUrls: ['./form-mode-selector.component.scss']
})
export class FormModeSelectorComponent implements OnInit {

  constructor(    private dialogRef: MatDialogRef<FormModeSelectorComponent>,
    ) { }

  ngOnInit(): void {
  }

  selectedMode(mode:any){
    let data=''
    if(mode==0){
      data='quick'
    }else if(mode==1){
      data='advanced'
    }
    this.dialogRef.close(data)
  }
}
