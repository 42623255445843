import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-header-extension',
  templateUrl: './header-extension.component.html',
  styleUrls: ['./header-extension.component.scss']
})
export class HeaderExtensionComponent implements OnInit {
  pendingCount: number = 0;
  imgConfiguration: any;

  constructor(
    public mainService:MainService,
    private router:Router,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    ) { }

  ngOnInit(): void {
    this.imgConfiguration=this.mainService.getImage()
    this.pendingUserCount()
  }
  getILText(key:string){
    return this.translate.instant(key)
  }

  pendingUserCount() {
    this.mainService.pendingUserCount('STATUS.NOT_REVIEWED').subscribe({
      next: (response: any) => {
        if (response.length > 0 && response[0].count) {
          this.pendingCount = response[0].count;
        } 
      },
      error: (error: any) => {
        this.snackBar.open(this.getILText('ERR_USER_COUNT'), 'Dismiss', {
          duration: 3000
        });
        throw error;
      }
    });
  }
}
