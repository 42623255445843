import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MainService } from 'src/app/services/main.service';
import { getLocalStorageItem } from 'src/app/shared/utility/utility';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-preview-page',
  templateUrl: './preview-page.component.html',
  styleUrls: ['./preview-page.component.scss']
})
export class PreviewPageComponent implements OnInit {

  @Output() exitPreview = new EventEmitter();
  @Input() assesmentFormData: any;
  selectedLanguage: string = getLocalStorageItem('language');
  languageForm:any={}
  imgStore: any;
  isFeatureHidden: boolean;
  isEditFormRedirection: boolean;
  imgConfiguration: any;
  appConfig: any;
  constructor(
    private translate: TranslateService,
    private service:MainService,
    private route:Router,
    public appService: AppConfigService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.appConfig=this.appService.getConfigData();
    this.imgConfiguration=this.service.getImage()
    console.log('IN')
    const urlSearchParams = new URLSearchParams(window.location.search);
    if(urlSearchParams.has('redirect') && urlSearchParams.get('redirect')=='close'){
      this.isEditFormRedirection=true
    }
    console.log(this.assesmentFormData ,"bbb")
    if (this.assesmentFormData['isAidPresent']) {
      this.languageForm = this.assesmentFormData;
      this.processForGridQuestions();
      this.titleService.setTitle(this.assesmentFormData.assessment.assessmentName +" - "+ this.appConfig['SURVEY_APP_CONFIG.APP_TITLE']);
      if (!this.languageForm['imageURL']) {
        this.fetchImgData()
      }
    } else {
      this.prepareAssessmentToRender()
      this.accessFeatures()
    }
  }

  // collectSkills(qps:any){
  //   qps.forEach((section:any)=>{
  //     section.sectionQuestionsList.forEach((ques:any)=>{
  //     })
  //   })
  // }

  processForGridQuestions() {
    this.languageForm?.questionPaperSectionList.forEach((section:any) => {
      section?.sectionQuestionsList.forEach((sectionQuestion:any)=> {
        if(sectionQuestion?.question?.questionType?.code=='QUESTION_TYPE.SINGLE_SELECT_GRID' || sectionQuestion?.question?.questionType?.code=='QUESTION_TYPE.MULTISELECT_GRID'){
          let gridData = this.initializeMatrix(sectionQuestion?.question?.mtfOptionList);
          console.log(gridData);
          sectionQuestion.question.gridData = gridData;
        }
      });
    });
  }

  navigateOnClose(){
    if(this.isEditFormRedirection){
      window.close(); 
    }else{
      this.route.navigateByUrl('/home/domain/'+this.languageForm.assessment['domain']?.split('.').pop().toLowerCase()+'/assessment')
    }
  }

  accessFeatures(){
    this.isFeatureHidden=true
  }

  fetchImgData(){
    this.languageForm['imageURL']='assets/images/domain/assessment-placeholder-2.png';

    // this.service.fetchCustomJson('static-data/defaultImgStore').subscribe({
    //   next:(response:any)=>{
    //     console.log(response);
    //     this.imgStore=response.images
    //     let tempImg=this.generateRandomImg(this.assesmentFormData.assessment['domain']?.split('.').pop().toLowerCase());
    //     this.languageForm['imageURL']='assets/images/default-img-container/'+tempImg;
    //   },error:err=>{
    //     throw err
    //   }
    // });
  }

  generateRandomImg(domain:string){
    if (this.imgStore.hasOwnProperty(domain)) {
      const domainImages = this.imgStore[domain];
      const randomIndex = Math.floor(Math.random() * domainImages.length);
      return domainImages[randomIndex];
    }
    return null;
  }

  prepareAssessmentToRender() {
    this.languageForm['imageURL']=this.assesmentFormData.imageUrl;
    this.languageForm['assessment']={
      assessmentName:this.getLangText(this.assesmentFormData.name),
      assessmentDesc:this.getLangText(this.assesmentFormData.desc),
      subDomain:this.assesmentFormData['subdomain'],
      domain:this.assesmentFormData['domain']
    }
    this.titleService.setTitle(this.languageForm['assessment'].assessmentName +" - "+ this.appConfig['SURVEY_APP_CONFIG.APP_TITLE']);
    this.languageForm['questionPaperSectionList']=[]
    this.assesmentFormData.contentForm.sections.forEach((section:any)=>{
      let questionList:any=[]
      section.questions.forEach((que:any)=>{        
        questionList.push({
          question:{
            questionText:this.getLangText(que.name),
            questionType:{
              code:que.questionType,
            },
            defaultOptionsList:this.getDefaultOptionList(que)
          }
        });
      })
      this.languageForm['questionPaperSectionList'].push({
        qpsName:this.getLangText(section.name),
        sectionQuestionsList:questionList
      })
    })
    console.log(this.languageForm)
  }

  getOptionModified(data:any){
    let optionList:any=[]
    data.forEach((op:any)=>{
      optionList.push({
        weight:op.optWeight,
        options:{
          optionCode:op.optionCode,
          optionName:this.getLangText(op.optname)
        }
      })
    });
    return optionList
  }

  getDefaultOptionList(data:any){
    console.log(data);
    
    if(data['questionOptions']){
      return this.getOptionModified(data.questionOptions)
    }else if(data['multiselect']){
      return this.getOptionModified(data.multiselect)
    }else if(data['objectiveremark']){
      return this.getOptionModified(data.objectiveremark)
    }else if(data['multiselectremark']){
      return this.getOptionModified(data.multiselectremark)
    }else if(data['scaleOptions']){
      let temp=[]
      for(let i=0;i<data['scaleOptions'].ratingSacleEnd;i++){
        temp.push({
          weight:0,
          options:{
            optionCode:'',
            optionName:i+1
          }
        })
      }
      return temp
    }
    return
  }

  back(event: any) {
    this.exitPreview.emit(event);
  }

  getLangText(textArray: any[]) {
    const text = textArray.find(text => text.language.code === this.selectedLanguage)
    return text?.text;
  }

  splitAtLast(inputString: string) {
    const lastPart = inputString?.split('.').pop() || '';
    const lastPartWithSpaces = lastPart?.replace(/_/g, ' ');
    return lastPartWithSpaces?lastPartWithSpaces:''
  }

  getPascalCase(text:string){
    return text?.toLowerCase().replace(/_/g, ' ').replace(/\w+/g, match => match.charAt(0).toUpperCase() + match.slice(1));
  }

  initializeMatrix(data:any) {
    console.log(data);
    const rowSet = new Set<number>();
    const colSet = new Set<number>();
    let rows:any=[];
    let columns:any =[];

    data.forEach((item:any) => {
      rowSet.add(item.optionLeft.id);
      colSet.add(item.optionRight.id);
    });

    rows = Array.from(rowSet).map(id => 
      data.find((item:any) => item.optionLeft.id === id)!.optionLeft
    ).sort((a, b) => a?.orderNo - b?.orderNo);

    columns = Array.from(colSet).map(id => 
    data.find((item:any) => item.optionRight.id === id)!.optionRight
    ).sort((a, b) => a?.orderNo - b?.orderNo);    
    return {"rows":rows,"columns":columns};
   }
  }
  
