import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AssessmentService } from 'src/app/services/assessment.service';
import { environment } from 'src/environments/environment';
import { MainService } from 'src/app/services/main.service';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-img-url-converter',
  templateUrl: './img-url-converter.component.html',
  styleUrls: ['./img-url-converter.component.scss']
})
export class ImgUrlConverterComponent implements OnInit {
    imgPresent: boolean;
    loader: boolean;
  imgConfiguration: any;
  appConfig: any;
  
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ImgUrlConverterComponent>,
    private assesmentService: AssessmentService,
    private mainService: MainService,
    private appService:AppConfigService) { }
  
    ngOnInit(): void {
      this.appConfig=this.appService.getConfigData();
      this.imgConfiguration=this.mainService.getImage()
      console.log(this.data)
      if(this.data['imgUploaded'].length){
        this.imgPresent=true
      }
    }
  
    async generateUrl(){
      let url=(<HTMLInputElement>document.getElementById('imgUrl')).value
      this.dialogRef.close({event: 'url',data:url});
      this.loader=false

    }
  
    onImageUpload(event: any) {
      this.loader=true
      console.log(event)
      const files = event.target.files;
      if (files.length === 0)
        return;
  
      const mimeType = files[0].type;
      if (mimeType.match(/image\/*/) == null) {
        console.log('Only images are supported.')
        return;
      }
  
      let imageFile = files[0];
  
      const reader = new FileReader();
      let imagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        let url = reader.result;
        let payload={
          image:imageFile
        }
  
        this.assesmentService.convertImgtoUrl(payload).then((response:any)=>{
          let finalResp=''
          if(environment.defaultOnwer=='echo'){
            finalResp=response?.resultBody?.url
          }else{
            finalResp=response
          }
          this.dialogRef.close({event: 'url',data:finalResp});
          this.loader=false
        }).catch((err: any)=>{
          this.loader=false
          this._snackBar.open('Unable to load image.Please try again.', 'Close', {
            duration: 3000,
            panelClass: ['blue-snackbar']
          });
        })
      }
    }
  
    deleteImg(){
      this.dialogRef.close({ event: 'del',data:''});
    }
    editImage(){
      this.imgPresent=false
    }
  }
