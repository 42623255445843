import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';


export const setLocalStorageItemEncrypt = (key: any, value: any,isTaker?:boolean): any => {
    const baseContext=isTaker?environment.respondentContext:environment.appContext
    localStorage.setItem(baseContext + '.' + key, CryptoJS.AES.encrypt(value, environment.encryptKey).toString())
}

export const getLocalStorageItemEncrypt = (key: any,isTaker?:boolean): any => {
    const baseContext=isTaker?environment.respondentContext:environment.appContext
    let data = localStorage.getItem(baseContext + '.' + key);
    if (data)
        return CryptoJS.AES.decrypt(data, environment.encryptKey).toString(CryptoJS.enc.Utf8);
    return data;
}

export const setSessionStorageItemEncrypt = (key: any, value: any,isTaker?:boolean): any => {
    const baseContext=isTaker?environment.respondentContext:environment.appContext
    sessionStorage.setItem(baseContext + '.' + key, CryptoJS.AES.encrypt(value, environment.encryptKey).toString())
}

export const getSessionStorageItemEncrypt = (key: any,isTaker?:boolean): any => {
    const baseContext=isTaker?environment.respondentContext:environment.appContext
    let data = sessionStorage.getItem(baseContext + '.' + key);
    if (data)
        return CryptoJS.AES.decrypt(data, environment.encryptKey).toString(CryptoJS.enc.Utf8);
    return data;
}


export const setLocalStorageItem = (key: any, value: any,isTaker?:boolean): any => {
    const baseContext=isTaker?environment.respondentContext:environment.appContext
    localStorage.setItem(baseContext + '.' + key, value)
}

export const getLocalStorageItem = (key: any,isTaker?:boolean): any => {
    const baseContext=isTaker?environment.respondentContext:environment.appContext
    return localStorage.getItem(baseContext + '.' + key);
}

export const removeLocalStorageItem = (key: any,isTaker?:boolean): any => {
    const baseContext=isTaker?environment.respondentContext:environment.appContext
    localStorage.removeItem(baseContext + '.'+key)
}


export const setSessionStorageItem = (key: any, value: any,isTaker?:boolean): any => {
    const baseContext=isTaker?environment.respondentContext:environment.appContext
    sessionStorage.setItem(baseContext + '.' + key, value)
}

export const getSessionStorageItem = (key: any,isTaker?:boolean): any => {
    const baseContext=isTaker?environment.respondentContext:environment.appContext
    return sessionStorage.getItem(baseContext + '.' + key);
}

export const removeSessionStorageItem = (key: any,isTaker?:boolean): any => {
    const baseContext=isTaker?environment.respondentContext:environment.appContext
    sessionStorage.removeItem(baseContext + '.'+key)
}

export const toTitleCase = (str: string): string => {
    return str.toLowerCase().replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
  };
  