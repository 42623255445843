import { NgModule } from "@angular/core";
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatExpansionModule} from '@angular/material/expansion';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { NgxSpinnerModule } from "ngx-spinner";
import {ClipboardModule} from '@angular/cdk/clipboard'; 
@NgModule({

    imports: [
        MatSelectModule,
        MatListModule,
        MatChipsModule,
        MatSidenavModule,
        
    ],
    exports: [
        MatCardModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MatDividerModule,
        MatSelectModule,
        MatRadioModule,
        MatDialogModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        MatButtonModule,
        MatMenuModule,
        MatTabsModule,
        MatProgressBarModule,
        MatListModule,
        MatChipsModule,
        MatSidenavModule,
        MatMenuModule,
        MatExpansionModule,
        MatTabsModule,
        NgxSpinnerModule,
        CdkAccordionModule,
        ClipboardModule
    ]
})
export class MaterialModules { }
