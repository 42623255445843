import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { SurveyService } from 'src/app/services/takerServices/survey.service';
import { getSessionStorageItem } from 'src/app/shared/utility/utility';
import { MainService } from 'src/app/services/main.service';
@Component({
  selector: 'app-user-umbrella',
  templateUrl: './user-umbrella.component.html',
  styleUrls: ['./user-umbrella.component.scss']
})
export class UserUmbrellaComponent implements OnInit {
  label: any;
  isSignIn: boolean = true;
  showLoader: boolean = false;
  forgotPassword: boolean = false;
  isSignUp: boolean = false;
  otpValidation: boolean = false;

  maxDate = moment(new Date()).format('YYYY-MM-DD');
  data: any;
  imgConfiguration: any;

  constructor(private surveyService: SurveyService,
    private mainService:MainService) { }

  ngOnInit(): void {
    this.imgConfiguration=this.mainService.getImage()
    console.log('TEST')
    this.surveyService.getJSON('i18n/' + getSessionStorageItem('language',true)).subscribe(
      {
        next: (jsonFileDATA: any) => {

          this.label = jsonFileDATA
          console.log(this.label)
          this.surveyService.setStoredData(jsonFileDATA);
        }, error: (err: any) => {
          throw err;
        }
      });
  }

  switch(event: any) {
    if (event == 'signup') {
      this.isSignUp = true;
      this.isSignIn = false;
      this.forgotPassword = false;
      this.otpValidation = false;
    } else if (event == 'signin') {
      this.isSignIn = true;
      this.isSignUp = false;
      this.forgotPassword = false;
      this.otpValidation = false;
    } else if (event == 'forgot-password') {
      this.forgotPassword = true;
      this.isSignIn = false;
      this.isSignUp = false;
      this.otpValidation = false;
    } else if (event == 'otp-validation') {
      this.otpValidation = true;
      this.forgotPassword = false;
      this.isSignIn = false;
      this.isSignUp = false;
    }
  }

  signupData(event: any) {
    this.data = event;
  }
}
