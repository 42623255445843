<div class="view-box h-100">
    <div class="dialog-box d-flex flex-lg-row flex-column">
        <div class="card" (click)="selectedMode(0)">
          <span class="assessment-text-bold-16 assessment-text-grey-nine">{{'LABEL.MODE.ONE' | translate}}</span>
          <p class="assessment-text-regular-13 assessment-text-grey-seven mt-3">{{'LABEL.MODE.ONE.DESC'| translate}}</p>
        </div>
    
        <div class="card ms-lg-4 ms-0" (click)="selectedMode(1)">
          <span class="assessment-text-bold-16 assessment-text-grey-nine">{{'LABEL.MODE.TWO'| translate}}</span>
          <p class="assessment-text-regular-13 assessment-text-grey-seven mt-3">{{'LABEL.MODE.TWO.DESC' | translate}}</p>
        </div>
      </div>
</div>