import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  getLocalStorageItem,
  getSessionStorageItem,
  removeLocalStorageItem,
} from '../shared/utility/utility';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  baseUrl: string = environment.baseUrl;

  url: string;
  assesmentSchema: any;
  assesment: any;
  assesmentInstId: number;
  formData:any
  previewQuestion:any;
  responseCount=new BehaviorSubject<any>(0);
  publishValidity=new BehaviorSubject<any>(true);
  previewShow= new BehaviorSubject<any>(true);
  publish=new BehaviorSubject<any>('');
  publishApproval=new BehaviorSubject<any>('');
  deleteForm=new BehaviorSubject<any>(false);
  formPublishStatus=new BehaviorSubject<any>('');
  langList = new BehaviorSubject<any>({});
  navFormList = new BehaviorSubject<any>([]);
  updatedLangList = this.langList.asObservable();
  activeContent = new BehaviorSubject<any>('1');
  activatePreviewAssessment = new BehaviorSubject<any>('');
  commonsReportDataSet = 'commons-report-service';
  commonsDomainSerivce = 'commons-domain-service';
  commonsAssessmentSerivce = 'assessment-service';
  advancedSettings: BehaviorSubject<any> = new BehaviorSubject({});
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private appService: AppConfigService
  ) {}
  setResponseCount(data:any){
    this.responseCount.next(data)
  }
  publishStatus(data:any){
    this.publishApproval.next(data)
  }
  formView(data:any){
    console.log(data)
    this.formPublishStatus.next(data)
  }
  enableDelete(data:any){
    this.deleteForm.next(data)
  }
  enablePublish(data:any){
    console.log(data)
    this.publishValidity.next(data)
  }
  publishForms(data:any){
    this.publish.next(data)
  }
  showPreview(data:any){
    this.previewShow.next(data)
  }
  previewFormData(data:any){
    this.formData=data
  }
  previewService(data:any){
    this.previewQuestion=data
  }
  updateLangList(data: any) {
    this.langList.next(data);
  }

  updateViewerNavList(data: any) {
    console.log(data)
    this.navFormList.next(data);
  }

  setAdvancedSettingsData(data: any): void {
    this.advancedSettings.next(data);
  }

  getAdvancedSettingsData(): BehaviorSubject<any> {
    return this.advancedSettings;
  }

  updateActiveContent(data: any) {
    this.activeContent.next(data);
  }

  previewActionMarker(data: boolean) {
    this.activatePreviewAssessment.next(data);
  }

  getQuestions(): Observable<any> {
    this.url = `${this.baseUrl}/${this.commonsAssessmentSerivce}/api/v1/assessments?page=1&size=10`;
    return this.http.get(this.url).pipe(catchError(this.handleError));
  }

  fetchJson(type: any) {
    return this.http.get('assets/json/config/customOwner/' + type + '.json');
  }

  fetchCustomJson(url: any) {
    return this.http.get('assets/json/' + url + '.json');
  }

  async createAssesmentSetup(assesmentSchema: any, settingsData: any) {
    this.assesmentSchema = assesmentSchema;
    let questionIds: any[] = [];

    const promiseOut = await this.assesmentSchema.contentForm.sections.map(
      async (section: any) => {
        const promiseInn = await section.questions.map(
          async (question: any, i: number) => {
            if (question.id == 0) {
              await this.createQuestion(question, assesmentSchema)
                .then((data: any) => {
                  question['questionId'] = data['id'];
                  questionIds.push(data.id);
                  //console.log('data', data);
                })
                .catch((error) => {
                  // Handle the promise rejection
                  console.error(error);
                });
            } else {
              question['questionId'] = question['id'];
              questionIds.push(question.id);
            }
          }
        );
        await Promise.all(promiseInn);
      }
    );
    await Promise.all(promiseOut);

    // if (preConfigData['uploadHeaderImg'] && this.assesmentSchema['image']) {
    //   await this.uploadHeaderImage(this.assesmentSchema).then(
    //     (imgRes: any) => {
    //       this.s3ImageUrl = imgRes?.resultBody?.url
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // }

    await this.createAssement(this.assesmentSchema, settingsData)
      .then(async (assesmentId: any) => {
        await this.getAssesmentById(assesmentId)
          .then((data) => {
            this.assesment = data;
            console.log('x1', data);
          })
          .catch((error) => {
            // Handle the promise rejection
            console.error(error);
          });
        console.log('x2', this.assesment);
      })
      .catch((error) => {
        // Handle the promise rejection
        console.error(error);
      });

    await this.createAssesmentInst(this.assesmentSchema, this.assesment)
      .then((assesmentInstId: any) => {
        this.assesmentInstId = assesmentInstId;
      })
      .catch((error) => {
        // Handle the promise rejection
        console.error(error);
      });

    await this.createQuestionPaper(this.assesmentSchema, this.assesment)
      .then((questionPaperId: any) => {
        console.log('check ', questionPaperId);
      })
      .catch((error) => {
        // Handle the promise rejection
        console.error(error);
      });
    console.log('x3', this.assesment);
    let promise = new Promise(async (resolve, reject) => {
      console.log('25');
      resolve(this.assesmentInstId);
    });

    return promise;
  }

  /*   async fetchData() {
      this.url = `${this.baseUrl}/commons-assessment-service/api/v1/assessments?page=1&size=10`;
      let a = await this.http.get<any>(this.url).pipe(delay(5000)).toPromise();
    } */

  async createQuestion(question: any, data?: any) {
    console.log(data);
    let url = `${this.baseUrl}/${this.commonsAssessmentSerivce}/api/v2/questions`;
    let questionPayload: any = {
      id: 0,
      tenant: 0,
      domain: data['domain'],
      subDomain: data['subdomain'],
      questionCode: 'FB_001',
      questionClass: {
        code: 'QUESTION_CLASS.QUESTION',
      },
      questionType: {
        code: question.questionType,
      },
      questionText: question.name,
      questionSubText: question.desc,
      questionName: [],
      weight: Number(question.marks),
      tenantId: 0,
      questionImageURL: question['quesImgUrl'],
      ltldskillList: question['skillStack'],
    };

    let payload: any;

    if (question.questionType === 'QUESTION_TYPE.OBJECTIVE') {
      payload = this.buildObjectiveQuesModel(question, questionPayload);
    } else if (question.questionType === 'QUESTION_TYPE.POLL_OPEN') {
      payload = this.buildPollQuesModel(question, questionPayload);
    } else if (question.questionType === 'QUESTION_TYPE.SCALE_RATING') {
      payload = this.buildScaleRatingQuesModel(question, questionPayload);
    } else if (
      question.questionType === 'QUESTION_TYPE.SUBJECTIVE_LONG' ||
      question.questionType === 'QUESTION_TYPE.SUBJECTIVE_SHORT' ||
      question.questionType === 'QUESTION_TYPE.DATE' ||
      question.questionType === 'QUESTION_TYPE.NUMBER' ||
      question.questionType === 'QUESTION_TYPE.FILE_UPLOAD'
    ) {
      payload = this.buildSubjectiveQuesModel(question, questionPayload);
    } else if (question.questionType === 'QUESTION_TYPE.DDOBJECTIVE') {
      payload = this.buildDDobjectivequesModel(question, questionPayload);
    } else if (question.questionType === 'QUESTION_TYPE.DDMULTISELECT') {
      payload = this.buildDDmultiselectsModel(question, questionPayload);
    } else if (question.questionType === 'QUESTION_TYPE.OBJECTIVE_SUBJECTIVE') {
      payload = this.buildObjectiveremarkModel(question, questionPayload);
    } else if (
      question.questionType === 'QUESTION_TYPE.MULTISELECT_SUBJECTIVE'
    ) {
      payload = this.builMultiselectremarkModel(question, questionPayload);
    } else if (question.questionType === 'QUESTION_TYPE.MULTISELECT') {
      payload = this.buildMultiSelectquesModel(question, questionPayload);
    } else if (
      question.questionType === 'QUESTION_TYPE.SINGLE_SELECT_GRID' ||
      question.questionType === 'QUESTION_TYPE.MULTISELECT_GRID'
    ) {
      payload = this.buildGridModel(question, questionPayload);
    } else {
      payload = questionPayload;
    }
    console.log(payload, 'last straw');

    let promise = new Promise(async (resolve, reject) => {
      await this.http
        .post(url, payload, {
          responseType: 'json',
        })
        .subscribe(
          (data) => {
            console.log('1', data);
            resolve(data);
          },
          (err) => {
            console.log('loging');
            reject('fail');
            throw err;
          }
        );
    });
    return promise;

    /*await this.http.post(url, questionPayload, {
      responseType:"json"
    }).toPromise(); */
  }

  async createReplicaQuestion(question: any) {
    let url = `${this.baseUrl}/${this.commonsAssessmentSerivce}/api/v1/questions/duplicate/${question.id}`;

    let promise = new Promise(async (resolve, reject) => {
      await this.http
        .post(url, null, {
          responseType: 'json',
        })
        .subscribe(
          (data) => {
            console.log('1', data);
            resolve(data);
          },
          (err) => {
            console.log('loging');
            reject('fail');
            throw err;
          }
        );
    });
    return promise;
  }

  getSettingsConfig(settingsData: any) {
    let config = [
      {
        configKey: 'score',
        configValue: settingsData?.score,
        id: 0,
      },
      {
        configKey: 'multiLingual',
        configValue: settingsData?.multiLingual,
        id: 0,
      },
      {
        configKey: 'childQuestions',
        configValue: settingsData?.childQuestions,
        id: 0,
      },
      {
        configKey: 'importQuestions',
        configValue: settingsData?.importQuestions,
        id: 0,
      },
    ];
    return config;
  }

  async createAssement(assesmentSchema: any, settingsData: any) {
    let appConfig: any = this.appService.getConfigData();
    let url = `${this.baseUrl}/${this.commonsAssessmentSerivce}/api/v1/assessments`;
    let formSubType = getLocalStorageItem('formSubType');
    let assesmentPayload: any = {
      id: 0,
      tenant: 0,
      domain: assesmentSchema['domain'],
      subDomain: assesmentSchema['subdomain'],
      assessmentCode: 'ASSESSMENT_FEEDBACK_FORM_FOR_COMMONS',
      baseLanguage: assesmentSchema['baseLanguage'],
      assessmentConfigs: this.getSettingsConfig(settingsData),
      assessmentType: {
        code: assesmentSchema.formType,
        labels: [
          {
            id: 1,
            text: 'Assessment type',
            language: {
              code: 'ENG',
              label: 'English',
            },
          },
        ],
        refClass: {
          code: 'REF.ASSESSMENT_TYPE',
        },
      },
      totallMarks: 0,
      context: appConfig['SURVEY_APP_CONFIG.DOMAIN_CONTEXT'],
      subjectCode: 'SUBJECT_FEEDBACK_FOR_COMMONS',
      assessmentContextList: [],
      assessmentDesc: assesmentSchema.desc,
      assessmentName: assesmentSchema.name,
    };
    if (formSubType && formSubType !== 'null' && formSubType !== 'undefined') {
      assesmentPayload['assessmentSubType'] = {
        code: this.getFormSubTypeCode(formSubType),
        refClass: {
          code: 'REF.ASSESSMENT_SUB_TYPE',
        },
      };
    }
    let promise = new Promise(async (resolve, reject) => {
      await this.http
        .post(url, assesmentPayload, {
          responseType: 'json',
        })
        .toPromise()
        .then((data) => {
          console.log('2', data);
          resolve(data);
        })
        .catch((error) => {
          // Handle the promise rejection
          console.error(error);
        });
    });
    return promise;
  }

  async getAssesmentById(asssementId: number) {
    const url = `${this.baseUrl}/${this.commonsAssessmentSerivce}/api/v1/assessments/${asssementId}`;

    let promise = new Promise(async (resolve, reject) => {
      await this.http
        .get(url)
        .toPromise()
        .then((data) => {
          console.log('3', data);
          resolve(data);
        })
        .catch((error) => {
          // Handle the promise rejection
          console.error(error);
        });
    });
    return promise;
  }

  async createAssesmentInst(assesmentSchema: any, assesment: any) {
    let visibilityHideFeature = false;
    if (getLocalStorageItem('hiddenVal')) {
      visibilityHideFeature = getLocalStorageItem('hiddenVal');
      removeLocalStorageItem('hiddenVal');
    }
    let url = `${this.baseUrl}/${this.commonsAssessmentSerivce}/api/v1/assessmentinstances`;
    let assesmentInstPayload: any = {
      id: 0,
      tenant: 0,
      asmtInstDesc: assesmentSchema.desc,
      asmtInstName: assesmentSchema.name,
      createdByActorId: getLocalStorageItem('assessmentActorId'),
      assessment: assesment,
      startDate: '2021-04-01T00:00:00.000+00:00',
      endDate: '2022-05-01T00:00:00.000+00:00',
      confirmationDate: '2021-05-25T10:28:50.000+00:00',
      isActive: true,
      isPublic: true,
      specificVisibility: false,
      imageURL: assesmentSchema['imageUrl'],
      scheduleStatus: {
        code: 'ASSESSMENT.' + assesmentSchema['status'],
      },
      hideAssesseInfo: visibilityHideFeature,
    };

    let promise = new Promise(async (resolve, reject) => {
      await this.http
        .post(url, assesmentInstPayload, {
          responseType: 'json',
        })
        .toPromise()
        .then((data) => {
          console.log('2', data);
          resolve(data);
        })
        .catch((error) => {
          // Handle the promise rejection
          console.error(error);
        });
    });
    return promise;
  }

  async uploadHeaderImage(assesmentSchema: any) {
    let appConfig: any = this.appService.getConfigData();
    let url = `${appConfig['SURVEY_APP_CONFIG.IMAGE_URL']}/upload/api/v1/image`;
    let imgToken = getLocalStorageItem('imageToken');
    let formData: any = new FormData();
    formData.append('filePath', assesmentSchema.image);
    const authHeader: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${imgToken}`,
    });
    let promise = new Promise(async (resolve, reject) => {
      try {
        const data = await this.http
          .post(url, formData, { headers: authHeader })
          .toPromise();
        console.log('formDataformDataformData', data);
        resolve(data);
      } catch (error) {
        console.log('test back');
        reject(error);
      }
    });
    return promise;
  }

  async invokeWebHook(instanceId: any, data: any) {
    let appConfig: any = this.appService.getConfigData();
    let url = `${appConfig['SURVEY_APP_CONFIG.IMAGE_URL']}/form/api/v1/builder/webhook`;
    let imgToken = getLocalStorageItem('imageToken');
    const authHeader: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${imgToken}`,
    });
    let promise = new Promise(async (resolve, reject) => {
      await this.http
        .post(url, null, {
          headers: authHeader,
          params: {
            data: data,
            instanceId: instanceId,
          },
        })
        .toPromise()
        .then(
          (data) => {
            console.log('invokeWebHook', data);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          // Handle the promise rejection
          console.error(error);
        });
    });
    return promise;
  }

  async createQuestionPaper(assesmentSchema: any, assesment: any) {
    let url = `${this.baseUrl}/${this.commonsAssessmentSerivce}/api/v1/assessmentQuestionPapers`;
    let questionPaperPayload: any = {
      id: 0,
      tenant: 0,
      assessment: assesment,
      assessmentQuestionPaperCode: 'QP_ASSESSMENT_FEEDBACK_FOR_COMMONS',
      aqpDesc: assesmentSchema.desc,
      aqpName: assesmentSchema.name,
    };
    let questionPaperSectionList: any[] = [];
    this.assesmentSchema.contentForm.sections.forEach(
      (section: any, index: number) => {
        let questionSectionPayload: any = {
          tenant: 0,
          id: 0,
          orderNo: index + 1,
          qpsName: section.name,
          qpsDesc: section.desc,
          qpsText: [],
        };

        let sectionQuestionsList: any[] = [];
        let questionTotalMarks: number = 0;
        let questionCount: number = 0;
        section.questions.forEach((question: any, index: number) => {
          let questionPayload: any = {
            id: 0,
            tenant: 0,
            questionNumber: index + 1,
            questionId: question.questionId,
            orderNo: index + 1,
            isActive: true,
            secQuestDesc: [],
            isMandatory: question.isMandatory,
          };
          questionTotalMarks += Number(question.marks);
          questionCount += 1;
          sectionQuestionsList.push(questionPayload);
        });
        questionSectionPayload['totalMarks'] = questionTotalMarks;
        questionSectionPayload['totalQuestions'] = questionCount;
        questionSectionPayload['totalQuestionsToBeAnswered'] = questionCount;
        questionSectionPayload['sectionQuestionsList'] = sectionQuestionsList;
        questionPaperSectionList.push(questionSectionPayload);
      }
    );

    questionPaperPayload['questionPaperSectionList'] = questionPaperSectionList;

    let promise = new Promise(async (resolve, reject) => {
      await this.http
        .post(url, questionPaperPayload, {
          responseType: 'json',
        })
        .toPromise()
        .then((data) => {
          console.log('25', data);
          resolve(data);
        })
        .catch((error) => {
          // Handle the promise rejection
          console.error(error);
        });
    });
    return promise;
  }

  buildMultiSelectquesModel(question: any, questionPayload: any) {
    let defaultOptionList: any[] = [];
    question.multiselect.forEach((option: any, index: number) => {
      let quesOption = {
        id: 0,
        orderNo: index + 1,
        tenant: 0,
        weight: option.optWeight ?? 0,
        options: {
          id: 0,
          optionCode: option.optionCode,
          tenant: 0,
          optionName: option.optname,
        },
        isCorrect: option.optWeight > 0 ? true : false,
      };
      defaultOptionList.push(quesOption);
    });
    questionPayload['defaultOptionsList'] = defaultOptionList;
    questionPayload['mtfOptionList'] = [];
    questionPayload['questionSubtype'] = {
      code: 'QUESTION_SUB_TYPE.MULTI_SELECT',
    };
    return questionPayload;
  }

  buildDDmultiselectsModel(question: any, questionPayload: any) {
    let defaultOptionList: any[] = [];
    question.ddmultiselect.forEach((option: any, index: number) => {
      let quesOption = {
        id: 0,
        orderNo: index + 1,
        tenant: 0,
        weight: 100,
        options: {
          id: 0,
          optionCode: option.optionCode,
          tenant: 0,
          optionName: option.optname,
        },
        isCorrect: option.optWeight > 0 ? true : false,
      };
      defaultOptionList.push(quesOption);
    });
    questionPayload['defaultOptionsList'] = defaultOptionList;
    questionPayload['mtfOptionList'] = [];
    questionPayload['questionSubtype'] = {
      code: 'QUESTION_SUB_TYPE.DD_MULTI_SELECT',
    };
    return questionPayload;
  }

  buildObjectiveremarkModel(question: any, questionPayload: any) {
    let defaultOptionList: any[] = [];
    question.objectiveremark.forEach((option: any, index: number) => {
      let quesOption = {
        id: 0,
        orderNo: index + 1,
        tenant: 0,
        weight: option.optWeight ?? 0,
        options: {
          id: 0,
          optionCode: option.optionCode,
          tenant: 0,
          optionName: option.optname,
        },
        isCorrect: option.optWeight > 0 ? true : false,
      };
      defaultOptionList.push(quesOption);
    });
    questionPayload['defaultOptionsList'] = defaultOptionList;
    questionPayload['mtfOptionList'] = [];
    questionPayload['questionSubtype'] = {
      code: 'QUESTION_SUB_TYPE.OBJECTIVE_SUBJECTIVE',
    };
    return questionPayload;
  }

  buildGridModel(question: any, questionPayload: any) {
    let mtfOptionList = [];

    let cntrlGrid: string = '';
    let supportCntrlGrid: string = '';
    if (question.questionType == 'QUESTION_TYPE.SINGLE_SELECT_GRID') {
      cntrlGrid = 'singlegrid';
      supportCntrlGrid = 'singlegridsupportCol';
    } else {
      cntrlGrid = 'multigrid';
      supportCntrlGrid = 'multigridsupportCol';
    }

    for (let i = 0; i < question[cntrlGrid].length; i++) {
      let newUUIDRow = crypto.randomUUID();
      for (let j = 0; j < question[supportCntrlGrid].length; j++) {
        if (!question[supportCntrlGrid][j]['uuidCol']) {
          question[supportCntrlGrid][j]['uuidCol'] = crypto.randomUUID();
        }
        let mtf = {
          id: 0,
          optionLeft: {
            id: 0,
            uuid: newUUIDRow,
            tenant: 0,
            optionName: question[cntrlGrid][i].optname,
            optionCode: question[cntrlGrid][i].optionCode,
            orderNo: i + 1,
          },
          optionRight: {
            id: 0,
            uuid: question[supportCntrlGrid][j]['uuidCol'],
            tenant: 0,
            optionName: question[supportCntrlGrid][j].optname,
            optionCode: question[supportCntrlGrid][j].optionCode,
            orderNo: j + 1,
          },
          tenant: 0,
          weight: 0,
        };
        mtfOptionList.push(mtf);
      }
    }
    questionPayload['mtfOptionList'] = mtfOptionList;
    questionPayload['defaultOptionsList'] = [];
    questionPayload['questionSubtype'] = {
      code: question.questionType,
    };

    return questionPayload;
  }

  optionLeft(i: number, question: any, cntrlGrid: any) {
    return {
      id: 0,
      uuid: crypto.randomUUID(),
      tenant: 0,
      optionName: question[cntrlGrid][i].optname,
      optionCode: question[cntrlGrid][i].optionCode,
      orderNo: i + 1,
    };
  }

  optionRight(question: any, j: number, supportCntrlGrid: any) {
    return {
      id: question[supportCntrlGrid][j].id ?? 0,
      uuid: question[supportCntrlGrid][j]['uuidCol'],
      tenant: 0,
      optionName: question[supportCntrlGrid][j].optname,
      optionCode: question[supportCntrlGrid][j].optionCode,
      orderNo: j + 1,
    };
  }

  mtfObjGrid(
    newUUIDRow: any,
    question: any,
    i: number,
    j: number,
    cntrlGrid: any,
    supportCntrlGrid: any,
    leftId: number
  ) {
    let mtf = {
      id: 0,
      optionLeft: this.optionLeft(i, question, cntrlGrid),
      optionRight: this.optionRight(question, j, supportCntrlGrid),
      tenant: 0,
      weight: 0,
    };
    return mtf;
  }

  builMultiselectremarkModel(question: any, questionPayload: any) {
    let defaultOptionList: any[] = [];
    question.multiselectremark.forEach((option: any, index: number) => {
      let quesOption = {
        id: 0,
        orderNo: index + 1,
        tenant: 0,
        weight: option.optWeight ?? 0,
        options: {
          id: 0,
          optionCode: option.optionCode,
          tenant: 0,
          optionName: option.optname,
        },
        isCorrect: option.optWeight > 0 ? true : false,
      };
      defaultOptionList.push(quesOption);
    });
    questionPayload['defaultOptionsList'] = defaultOptionList;
    questionPayload['mtfOptionList'] = [];
    questionPayload['questionSubtype'] = {
      code: 'QUESTION_SUB_TYPE.MULTISELECT_SUBJECTIVE',
    };
    return questionPayload;
  }

  buildDDobjectivequesModel(question: any, questionPayload: any) {
    let defaultOptionList: any[] = [];
    question.ddobjective.forEach((option: any, index: number) => {
      let quesOption = {
        id: 0,
        orderNo: index + 1,
        tenant: 0,
        weight: 100,
        options: {
          id: 0,
          optionCode: option.optionCode,
          tenant: 0,
          optionName: option.optname,
        },
        isCorrect: option.optWeight > 0 ? true : false,
      };
      defaultOptionList.push(quesOption);
    });
    questionPayload['defaultOptionsList'] = defaultOptionList;
    questionPayload['mtfOptionList'] = [];
    questionPayload['questionSubtype'] = {
      code: 'QUESTION_SUB_TYPE.DD_OBJECTIVE',
    };
    return questionPayload;
  }

  getChildQuestionList(option: any, qtype: string) {
    let childId: number[] = [];
    if (qtype == 'QUESTION_TYPE.OBJECTIVE' && option['tree']?.length) {
      option['tree'].forEach((child: any) => {
        childId.push(child.id);
      });
    }
    return childId;
  }

  buildObjectiveQuesModel(question: any, questionPayload: any) {
    let defaultOptionList: any[] = [];
    question.questionOptions.forEach((option: any, index: number) => {
      let quesOption = {
        id: 0,
        orderNo: index + 1,
        tenant: 0,
        weight: option.optWeight ?? 0,
        childQuestionList: this.getChildQuestionList(
          option,
          'QUESTION_TYPE.OBJECTIVE'
        ),
        options: {
          id: 0,
          optionCode: option.optionCode,
          tenant: 0,
          optionName: option.optname,
        },
        isCorrect: option.optWeight > 0 ? true : false,
      };
      defaultOptionList.push(quesOption);
    });
    questionPayload['defaultOptionsList'] = defaultOptionList;
    questionPayload['mtfOptionList'] = [];

    questionPayload['questionSubtype'] = {
      code: 'QUESTION_SUB_TYPE.OBJECTIVE',
    };
    return questionPayload;
  }

  buildPollQuesModel(question: any, questionPayload: any) {
    let defaultOptionList: any[] = [];
    question.pollvote.forEach((option: any, index: number) => {
      let quesOption = {
        id: 0,
        orderNo: index + 1,
        tenant: 0,
        weight: option.optWeight ?? 0,
        options: {
          id: 0,
          optionCode: option.optionCode,
          tenant: 0,
          optionName: option.optname,
        },
        isCorrect: option.optWeight > 0 ? true : false,
      };
      defaultOptionList.push(quesOption);
    });
    questionPayload['defaultOptionsList'] = defaultOptionList;
    questionPayload['mtfOptionList'] = [];
    questionPayload['questionSubtype'] = {
      code: 'QUESTION_SUB_TYPE.POLL_OPEN.PRIVATE',
    };
    return questionPayload;
  }

  buildSubjectiveQuesModel(question: any, questionPayload: any) {
    questionPayload['defaultOptionsList'] = [];
    questionPayload['mtfOptionList'] = [];
    questionPayload['questionSubtype'] = {
      code: 'QUESTION_SUB_TYPE.SUBJECTIVE',
    };
    return questionPayload;
  }

  buildScaleRatingQuesModel(question: any, questionPayload: any) {
    let defaultOptionList: any[] = [];

    console.log(question.scaleOptions.ratingSacleStart);
    console.log(question.scaleOptions.ratingSacleEnd);

    let charCode: number = 65;

    let endScale: number = question.scaleOptions.ratingSacleEnd;

    for (let index = 1; index <= endScale; index++) {
      console.log('Block statement execution no.' + index);

      let quesOption = {
        id: 0,
        tenant: 0,
        orderNo: index,
        weight: 100,
        options: {
          id: 0,
          optionCode: String.fromCharCode(charCode++),
          tenant: 0,
          optionName: [
            {
              id: 0,
              text: index,
              language: {
                code: 'ENG',
                label: 'English',
              },
            },
          ],
        },
        isCorrect: true,
      };
      defaultOptionList.push(quesOption);
    }

    questionPayload['defaultOptionsList'] = defaultOptionList;
    questionPayload['mtfOptionList'] = [];
    questionPayload['questionSubtype'] = {
      code: 'QUESTION_SUB_TYPE.OBJECTIVE',
    };
    return questionPayload;
  }

  getFormTypeCode(formType: string) {
    switch (formType) {
      case 'survey':
        return 'ASSESSMENT_TYPE.SURVEY';
      case 'feedback':
        return 'ASSESSMENT_TYPE.FEEDBACK';
      case 'assesment':
        return 'ASSESSMENT_TYPE.ASSESSMENT';
      case 'assessment':
        return 'ASSESSMENT_TYPE.ASSESSMENT';
      case 'general':
        return 'ASSESSMENT_TYPE.GENERAL';
      case 'observation_sw':
        return 'ASSESSMENT_TYPE.OBSERVATION_SW';
      case 'observation_er':
        return 'ASSESSMENT_TYPE.OBSERVATION_ER';
      case 'observation_cl':
        return 'ASSESSMENT_TYPE.OBSERVATION_CL';
      case 'preassessment':
        return 'ASSESSMENT_TYPE.PREASSESSMENT';
      case 'midassessment':
        return 'ASSESSMENT_TYPE.MIDASSESSMENT';
      case 'postassessment':
        return 'ASSESSMENT_TYPE.POSTASSESSMENT';
      case 'batch_registration':
        return 'ASSESSMENT_TYPE.BATCH_REGISTRATION';
      case 'case_presentation':
        return 'ASSESSMENT_TYPE.CASE_PRESENTATION';
      case 'poll':
        return 'ASSESSMENT_TYPE.POLL';
      default:
        return '';
    }
  }

  getFormSubTypeCode(formType: string) {
    return 'ASSESSMENT_SUB_TYPE.' + formType;
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  getAssessmentInstanceWithAssessmentInstanceId(assessmentInstanceID: any) {
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v2/assessmentinstances/' +
        assessmentInstanceID
    );
  }
  getAIAssessesWithAssessmentInstanceId(assessmentInstanceID: any) {
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v1/assessmentInstanceAssesses/assessmentinstance/' +
        assessmentInstanceID
    );
  }
  getAssessmentWithId(assessmentID: any) {
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v1/assessments/' +
        assessmentID
    );
  }
  getAssessmentQuestionPaperWithAssessmentID(assessmentID: any) {
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v2/assessmentQuestionPapers?instanceId=' +
        assessmentID
    );
  }
  getQuestionResponseExists(questionId: any) {
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v1/questions/response-exists/' +
        questionId
    );
  }
  getQuestionsWithIdList(aid: number, questionIDList: any) {
    let httpParams = new HttpParams();
    questionIDList.forEach((questionId: any) => {
      httpParams = httpParams.append('questionIds', questionId);
    });
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v2/questions/questions/instance/' +
        aid,
      { params: httpParams }
    );
  }

  getChildQuestionsWithIdList(aid: number, questionIDList: any) {
    let httpParams = new HttpParams();
    questionIDList.forEach((questionId: any) => {
      httpParams = httpParams.append('questionIds', questionId);
    });
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v2/questions/childquestion/instance/' +
        aid,
      { params: httpParams }
    );
  }

  getQuestionsByQuestionIds(questionIDList: any) {
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v1/questions/questionids?questionIds=' +
        questionIDList
    );
  }

  patchQuestionWithId(questionPayload: any) {
    return this.http.patch(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v2/questions',
      questionPayload
    );
  }
  patchAssessmentQuestionPaper(assessmentPayload: any) {
    return this.http.patch(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v3/assessmentQuestionPapers',
      assessmentPayload
    );
  }
  putUpdateSectionForIsMandatory(params: any) {
    return this.http.put(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v1/assessmentQuestionPapers/mandatory/' +
        params.assessmentQuestionPaperId +
        '/' +
        params.sectionQuestionId +
        '/' +
        params.toggleValue,
      null
    );
  }
  patchAssessmentInstances(assessmentPayload: any) {
    return this.http.patch(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v2/assessmentinstances',
      assessmentPayload
    );
  }
  deleteAssessmentInstance(aid: number): Observable<any> {
    return this.http.delete(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v2/assessmentinstances/' +
        aid
    );
  }
  patchAssessmentApi(assessmentPayload: any) {
    return this.http.patch(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v2/assessments',
      assessmentPayload
    );
  }
  postAssessment(payload: any) {
    return this.http.post(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v1/assessments',
      payload
    );
  }
  postAssessmentInst(payload: any) {
    return this.http.post(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v1/assessmentinstances',
      payload
    );
  }
  postAssessmentQuestionPaper(payload: any) {
    return this.http.post(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v2/assessmentQuestionPapers',
      payload
    );
  }
  postQIDforReplica(id: any) {
    let forEntityId=getSessionStorageItem('assessedEID',true)
    let forEntityType=getLocalStorageItem('assessedForEntityType')
    return this.http.post(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v1/questions/duplicate/' +
        id,{
        forEntityId:
        forEntityId,
        forEntityType:
        forEntityType}
    );
  }
  getAllAssessmentInstances(params: any) {
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v1/assessmentinstances?' +
        'page=' +
        params.page +
        '&size=' +
        params.size
    );
  }
  postDuplicateAssessment(payload: any) {
    console.log(payload)
    return this.http.post(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v1/assessmentinstances/duplicate?assessmentInstanceId=' +
        payload.aid +
        '&name=' +
        payload.name +
        '&hideAssesseInfo=' +
        payload.hideInfo +
        '&creatorActorId='+
        payload.creatorActorId+
        '&forEntityId='+
        payload.forEntityId+
        '&forEntityType='+
        payload.forEntityType,

      null
    );
  }
  getLanguageList(params: any) {
    let promise = new Promise((resolve, reject) => {
      this.http
        .get(
          environment.baseUrl +
            '/ref-data-service/api/v1/languages?direction=ASC' +
            '&page=' +
            params.page +
            '&size=' +
            params.size,
          {
            responseType: 'json',
            observe: 'response',
          }
        )
        .toPromise()
        .then((lang: any) => {
          console.log(lang);
          resolve(lang);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
    return promise;
  }
  getRefDataTypes(params: any) {
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v1/ref-data/class/' +
        params.classCode +
        '?' +
        'page=' +
        params.page +
        '&size=' +
        params.size,
      { responseType: 'json', observe: 'response' }
    );
  }
  async convertImgtoUrl(imgPayload: any) {
    let appConfig: any = this.appService.getConfigData();

    if (environment.defaultOnwer == 'echo') {
      return this.uploadHeaderImage(imgPayload);
    } else {
      const imageData: FormData = new FormData();
      imageData.append('file', imgPayload.image);

      let promise = new Promise(async (resolve, reject) => {
        await this.http
          .post(
            environment.baseUrl +
              '/' +
              this.commonsAssessmentSerivce +
              '/api/v1/attachment/assessment?entityType=ENTITY_TYPE.ASSESSMENT_QUESTION',
            imageData,
            { responseType: 'text' }
          )
          .toPromise()
          .then(
            (data) => {
              console.log('formDataformDataformData', data);
              resolve(data);
            },
            (error) => {
              console.log('test back');
              reject(error);
            }
          )
          .catch((error) => {
            // Handle the promise rejection
            console.error(error);
          });
      });
      return promise;
    }
  }

  getAssessmentInfo(params: any) {
    let httpParams = new HttpParams({
      fromObject: {
        language: params.lang,
        page: params.page,
        size: params.size,
        ownedBy: params.owner,
        sortBy: params.sortBy,
        sortOrder: params.sortOrder,
        context: params.context,
      },
    });

    if (params.status) {
      httpParams = httpParams.set('status', 'ASSESSMENT.' + params.status);
    }
    if (params.domain) {
      httpParams = httpParams.set('domain', params.domain);
    }
    if (params.text && params.text.trim().length) {
      httpParams = httpParams.set('text', params.text);
    }

    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v2/assessmentinstances/info',
      {
        params: httpParams,
        responseType: 'json',
        observe: 'response',
      }
    );
  }

  getExistingQuestionsList(params: any) {
    let httpParams = new HttpParams({
      fromObject: {
        language: params.lang,
        page: params.page,
        size: params.size,
        domain: params.domain,
      },
    });
    if (params.questionType) {
      httpParams = httpParams.set(
        'questionType',
        'QUESTION_TYPE.' + params.questionType
      );
    }
    if (params.text) {
      httpParams = httpParams.set('questionText', params.text);
    }
    if (params.type !== 'ALL') {
      httpParams = httpParams.set('questionType', params.type);
    }

    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v2/questions/question/search',
      {
        params: httpParams,
        responseType: 'json',
        observe: 'response',
      }
    );
  }

  getCountAssessmentResponses(params: any) {
    let httpParams = new HttpParams({
      fromObject: {
        page: 0,
        size: 0,
        params: params.paramsEntity,
      },
    });
    console.log(params);
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsReportDataSet +
        '/api/v3/datasets/name/ASSESSMENT_ORG-ANALYTICS-GetRespondentCount/execute',
      {
        params: httpParams,
        responseType: 'json',
        observe: 'response',
      }
    );
  }

  getAssessmentResponses(params: any) {
    let httpParams = new HttpParams({
      fromObject: {
        page: params.page,
        size: params.size,
        params: params.paramsEntity,
      },
    });
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsReportDataSet +
        '/api/v3/datasets/name/ASSESSMENT_ORG-ANALYTICS-GetRespondent/execute',
      {
        params: httpParams,
        responseType: 'json',
        observe: 'response',
      }
    );
  }
  getAssesseResponse(params: any) {
    let httpParams = new HttpParams({
      fromObject: {
        page: params.page,
        size: params.size,
        params: params.paramsEntity,
      },
    });
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsReportDataSet +
        '/api/v3/datasets/name/ASSESSMENT_ORG-ANALYTICS-GET_ASSESSE_RESPONSES/execute',
      {
        params: httpParams,
        responseType: 'json',
        observe: 'response',
      }
    );
  }

  getDatasetMetaData(params: any) {
    let httpParams = new HttpParams({
      fromObject: {
        page: params.page,
        size: params.size,
        params: params.paramsEntity,
      },
    });
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsReportDataSet +
        '/api/v3/datasets/name/' +
        params.name +
        '/execute',
      {
        params: httpParams,
        responseType: 'json',
        observe: 'response',
      }
    );
  }

  getDownloadFIle(params: any) {
    let httpParams = new HttpParams({
      fromObject: {
        fileType: 'application/zip',
      },
    });
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsReportDataSet +
        '/api/v1/datasets/name/ASSESSMENT_ORG-ANALYTICS_EXCEL-GET_ASSESSE_RESPONSES-SCORE/execute/download?assessmentInstanceId=' +
        params.assessmentInstanceId +
        '&language=ENG',
      {
        params: httpParams,
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  postAssessmentInstAccessor(payload: any) {
    return this.http.post(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v1/assessment-instance-accessor/create?instanceId=' +
        payload.id +
        payload.login,
      null
    );
  }

  deleteAssessmentInstAccessor(payload: any) {
    return this.http.delete(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v1/assessment-instance-accessor/remove?instanceId=' +
        payload.id +
        payload.login
    );
  }

  downloadResultsForRespondents(assessmentInstanceId: any) {
    let httpParams = new HttpParams({
      fromObject: {
        fileType: 'application/zip',
      },
    });
    return this.http.get(
      environment.baseUrl +
        '/' +
        'assessment-service/api/v1/assessmentresponsereport/assessment-reporting-query?assessmentInstanceId=' +
        assessmentInstanceId +
        '&language=' +
        getLocalStorageItem('form-language') +
        '&delimiter=%2C',
      {
        params: httpParams,
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  getAccessorInfo(params: any) {
    let httpParams = new HttpParams({
      fromObject: {
        page: params.page,
        size: params.size,
        instanceId: params.aid,
      },
    });
    return this.http.get(
      environment.baseUrl +
        '/' +
        this.commonsAssessmentSerivce +
        '/api/v1/assessment-instance-accessor/',
      {
        params: httpParams,
        responseType: 'json',
        observe: 'response',
      }
    );
  }
}
